// atoms.js
import { atom } from 'recoil';

export const totalAmount = atom({
  key: 'totalAmount', // unique ID
  default: 0, // default value
});

export const savings = atom({
  key: 'savings', // unique ID
  default: 0, // default value
});

export const originalPrice = atom({
  key: 'originalPrice', // unique ID
  default: 0, // default value
});

export const discountedPrice = atom({
  key: 'discountedPrice', // unique ID
  default: 0, // default value
});

export const inDate = atom({
  key: 'inDate', // unique ID
  default: "", // default value
});

export const outDate = atom({
  key: 'outDate', // unique ID
  default: "", // default value
});

export const totalGuests = atom({
  key: 'totalGuests',
  default: 0
})

export const totalNightNumber = atom({
  key: 'totalNights',
  default: 0
})

export const cleaningCharge = atom ({
  key: 'cleaningCharge',
  default: 0
})

export const serviceCharge = atom({
  key: 'serviceCharge',
  default: 0
})

export const subAmount = atom({
  key: 'subAmount',
  default: 0
})

export const nightRate = atom({
  key: 'nightRate',
  default: 0
})

export const startRecoilDate = atom({
  key: 'startDate',
  default: null
})

export const endRecoilDate = atom({
  key: 'endDate',
  default: null
})

export const isRecoilCalendarOpen = atom({
  key: 'isCalendarOpen',
  default: false
})

export const nonRefund = atom({
  key: 'nonRefund',
  default: false
})
