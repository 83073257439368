import L from "leaflet";
import "leaflet/dist/leaflet.css";
import { Mail, Phone, Star, Trophy } from "lucide-react";
import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";
import { Button } from "../components/ui/button";
//@ts-ignore
import icon from "leaflet/dist/images/marker-icon.png";
//@ts-ignore
import iconShadow from "leaflet/dist/images/marker-shadow.png";
import { inDate,outDate,totalGuests, totalNightNumber, totalAmount ,cleaningCharge, serviceCharge, discountedPrice, subAmount, nightRate} from "../recoil/atom";
import { format } from "date-fns";
import { Card, CardContent } from "../components/ui/card";
import { useRecoilValue } from "recoil";
import { FaPrint } from "react-icons/fa";

export default function LastPage() {

  const startDate = useRecoilValue(inDate)
  const endDate  = useRecoilValue(outDate)
  const Guests = useRecoilValue(totalGuests)

  const totalNights = useRecoilValue(totalNightNumber)
  const cleaning = useRecoilValue(cleaningCharge)
  const service = useRecoilValue(serviceCharge)
  const discount = useRecoilValue(discountedPrice)
  const total = useRecoilValue(totalAmount)
  const subTotal = useRecoilValue(subAmount)
  const oneNight = useRecoilValue(nightRate)

  return (
    <div className="min-h-screen bg-white">
      {/* Header */}
      <header className="border-b">
        <div className="container mx-auto px-4 py-4">
          <img
            src="/placeholder.svg"
            alt="Airbnb"
            width={102}
            height={32}
            className="w-[102px] h-8"
          />
        </div>
      </header>

      <main className="container mx-auto px-4 py-8 max-w-3xl">
        {/* Confirmation Message */}
        <div className="flex justify-end">
          <button className="bg-blue-500 p-2 text-white rounded-sm flex items-center">
            <FaPrint className="mr-3" /> Print full verstion
          </button>
        </div>
        <div className="mb-8">
          <h1 className="text-2xl font-semibold mb-4">
            Reservation Confirmed!
          </h1>
          <p className="text-gray-700">
            Your reservation is now confirmed!{" "}
            <span className="font-semibold">
              You will also receive an email shortly with the invoice and
              detailed payment instructions.
            </span>{" "}
            Please remember that payment must be completed within 24 hours to
            secure your booking. If payment is not received, the reservation
            will be canceled and made available for others to book.
          </p>
        </div>

        {/* Property Card */}
        <Card className="p-4 mb-8">
          <div className="flex gap-4">
            <img
              src="/room2.png"
              alt="Property"
              width={100}
              height={100}
              className="rounded-lg object-cover"
            />
            <div>
              <h2 className="font-semibold">
                Historical Center Wooden Studio near Big Square
              </h2>
              <p className="text-sm text-gray-500">Entire condo</p>
              <div className="flex items-center gap-1 mt-1">
                <Star className="w-4 h-4 fill-current" />
                <span className="text-sm">4.96 (531 reviews)</span>
                <Trophy className="w-4 h-4 ml-2" />
                <span className="text-sm">Superhost</span>
              </div>
            </div>
          </div>
        </Card>

        {/* Trip Details */}
        <Card>
          <CardContent className="p-4 ">
            <div className="flex justify-between items-center py-2">
              <div>
                <div className="font-medium">Dates</div>
                <div className="text-sm text-muted-foreground">
                  {format(
                    new Date(startDate),
                    // dates.from
                    "MMM d, yyyy"
                  )}{" "}
                  -{" "}
                  {format(
                    new Date(endDate),
                    // dates.to
                    "MMM d, yyyy"
                  )}
                </div>
              </div>
              {/* <Button variant="link" className="h-auto p-0">
                Edit
              </Button> */}
            </div>
            <div className="flex justify-between items-center py-2">
              <div>
                <div className="font-medium">Guests</div>
                <div className="text-sm text-muted-foreground">
                  {
                    Guests
                    } {
                      Guests
                       === 1 ? 'guest' : 'guests'
                  }
                </div>
              </div>
              {/* <Button variant="link" className="h-auto p-0">
                Edit
              </Button> */}
            </div>
          </CardContent>
        </Card>

        {/* Price Details */}
        <Card className="p-4 mb-8 mt-5">
          <h2 className="text-xl font-semibold mb-4">Price details</h2>
          <div className="space-y-2">
            <div className="flex justify-between">
              <span>{oneNight} × {totalNights} night</span>
              <span>{subTotal} lei</span>
            </div>
            <div className="flex justify-between">
              <span>Airbnb service fee</span>
              <span>{service} lei</span>
            </div>
            <div className="flex justify-between text-green-500">
              <span>Weekly Stay Discount + Coupen discount</span>
              <span>-{discount} lei</span>
            </div>
            <div className="flex justify-between">
              <span>Cleaning fee</span>
              <span>{cleaning} lei</span>
            </div>
            <div className="flex justify-between font-semibold pt-2 border-t mt-2">
              <span>Total (RON)</span>
              <span>{total} lei</span>
            </div>
          </div>
        </Card>

        {/* Location */}
        <div className="mb-8">
          <h2 className="text-xl font-semibold mb-4">Where you&apos;ll be</h2>
          <Card className="p-4">
            <div className="bg-gray-100 h-64 rounded-lg mb-4 relative">
              <MapComponent />
            </div>
            <h3 className="font-semibold">
              Watergraafsmeer, North Holland, Netherlands
            </h3>
            <p className="text-gray-600 mt-2">
              From our place, you can easily take a stroll (20-25 minutes) or
              bike (8-10 minutes) along the Amstel river to the waterlooplein &
              center area ....
            </p>
          </Card>
        </div>

        {/* Contact Property */}
        <div>
          <h2 className="text-xl font-semibold mb-4">Contact property</h2>
          <p className="text-gray-600 mb-4">
            Got a question or need to arrange something for your stay?
          </p>
          <div className="space-y-4">
            <div>
              <h3 className="font-medium mb-2">Email property</h3>
              <p className="text-sm text-gray-600">
                Send a message to the property, they&apos;ll respond as soon as
                possible
              </p>
              <Button className="mt-2" variant="outline">
                <Mail className="mr-2 h-4 w-4" />
                Send an email
              </Button>
            </div>
            <div>
              <h3 className="font-medium mb-2">Other options</h3>
              <Button variant="outline">
                <Phone className="mr-2 h-4 w-4" />
                Call +351 915 210 381
              </Button>
            </div>
          </div>
        </div>
      </main>

      {/* Footer */}
      {/* <footer className="border-t mt-12">
        <div className="container mx-auto px-4 py-4">
          <div className="flex justify-between items-center">
            <div className="text-sm text-gray-500">
              © 2024 Airbnb, Inc. · Terms · Sitemap · Privacy · Your Privacy Choices
            </div>
            <div className="flex items-center gap-4">
              <Button variant="ghost" size="sm">English (US)</Button>
              <Button variant="ghost" size="sm">lei RON</Button>
            </div>
          </div>
        </div>
      </footer> */}
    </div>
  );
}

const defaultIcon = L.icon({
  iconUrl: icon,
  shadowUrl: iconShadow,
});
L.Marker.prototype.options.icon = defaultIcon;

const MapComponent = () => {
  const position = [51.505, -0.09]; // Latitude, Longitude (London as an example)

  return (
    <div className="w-full h-full">
      <MapContainer
        //@ts-ignore
        center={position}
        zoom={13}
        style={{ height: "100%", width: "100%" }}
      >
        {/* Tile Layer using OpenStreetMap */}
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          //@ts-ignore
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        />

        {/* Marker with Popup */}
        <Marker position={position}>
          <Popup>
            A marker in London. <br /> Easily customizable.
          </Popup>
        </Marker>
      </MapContainer>
    </div>
  );
};
