// import React from 'react';
// import { Shield, Star, Award, MessageCircle } from 'lucide-react';
// import { Button } from "./ui/button";
// import { Avatar, AvatarFallback, AvatarImage } from "./ui/avatar";
// import { Card, CardContent } from "./ui/card";
// import { Badge } from "./ui/badge";

// export function MeetYourHost() {
//   return (
//     <div className='md:w-[1200px] '>
//     <div className="py-12">
//       <h2 className="text-2xl font-semibold mb-8">Meet your host</h2>
      
//       <Card>
//         <CardContent className="p-6">
//           <div className="flex gap-6">
//             <div className="flex-shrink-0">
//               <Avatar className="h-32 w-32">
//                 <AvatarImage src="/placeholder.svg?height=128&width=128" alt="Rob & Fang" />
//                 <AvatarFallback>RF</AvatarFallback>
//               </Avatar>
//             </div>
            
//             <div className="flex-grow space-y-4">
//               <div>
//                 <h3 className="text-xl font-semibold">Rob & Fang</h3>
//                 <p className="text-muted-foreground">Superhost · Hosting for 8 years</p>
//               </div>
              
//               <div className="grid grid-cols-2 gap-4">
//                 <div className="flex items-center gap-2">
//                   <Star className="h-5 w-5" />
//                   <span>524 Reviews</span>
//                 </div>
//                 <div className="flex items-center gap-2">
//                   <Shield className="h-5 w-5" />
//                   <span>Identity verified</span>
//                 </div>
//                 <div className="flex items-center gap-2">
//                   <Award className="h-5 w-5" />
//                   <span>Superhost</span>
//                 </div>
//                 <div className="flex items-center gap-2">
//                   <MessageCircle className="h-5 w-5" />
//                   <span>Quick responses</span>
//                 </div>
//               </div>
              
//               <div className="space-y-2">
//                 <p>Languages: English, Dutch, Mandarin</p>
//                 <p>Response rate: 100%</p>
//                 <p>Response time: within an hour</p>
//               </div>
              
//               <Button className="w-full" variant="outline">Contact Host</Button>
              
//               <div className="text-sm text-muted-foreground">
//                 To protect your payment, never transfer money or communicate outside of the Airbnb website or app.
//               </div>
//             </div>
//           </div>
//         </CardContent>
//       </Card>
//     </div>
// </div>
//   );
// }


import { Badge } from "./ui/badge";
import { Button } from "./ui/button";
import { Card, CardContent } from "./ui/card";
import { Building2, Globe2, MessageSquare, Shield } from "lucide-react";

export default function MeetYourHost() {
  return (
    <div className="md:w-[1200px] md:flex md:justify-center">
    <div className=" md:w-[1200px] mx-auto p-4">
      <h1 className="text-2xl font-semibold mb-6">Meet your Host</h1>

      <div className="grid md:grid-cols-2 gap-8">
        {/* Host Card */}
        <Card className="relative">
          <CardContent className="p-6">
            {/* Host Image and Badge */}
            <div className="relative mb-4">
              <img
                src="https://media.licdn.com/dms/image/D5603AQFfgVOzSHBDAw/profile-displayphoto-shrink_200_200/0/1713388726768?e=2147483647&v=beta&t=b991v82l2cR7JhAQB28UCflgtfPDGxGCXmDJaF3GMqU"
                alt="Host profile"
                className="w-24 h-24 rounded-full object-cover"
              />
              <Badge className="absolute bottom-0 right-0 bg-pink-600 text-white border-0">
                <MessageSquare className="w-3 h-3 mr-1" />
                Superhost
              </Badge>
            </div>

            {/* Host Details */}
            <div className="space-y-4">
              <div>
                <h2 className="text-xl font-semibold">Rob & Fang</h2>
                <p className="text-sm text-muted-foreground flex items-center gap-1">
                  <Badge variant="secondary">Superhost</Badge>
                </p>
              </div>

              {/* Host Stats */}
              <div className="grid grid-cols-3 gap-4 py-4 border-y">
                <div>
                  <p className="text-2xl font-semibold">568</p>
                  <p className="text-sm text-muted-foreground">Reviews</p>
                </div>
                <div>
                  <p className="text-2xl font-semibold">4.9★</p>
                  <p className="text-sm text-muted-foreground">Rating</p>
                </div>
                <div>
                  <p className="text-2xl font-semibold">13</p>
                  <p className="text-sm text-muted-foreground">Years hosting</p>
                </div>
              </div>

              {/* Host Information */}
              <div className="space-y-3">
                <div className="flex gap-3">
                  <Building2 className="w-5 h-5 text-muted-foreground" />
                  <p className="text-sm">My work: Architecture in Development, Nest Project</p>
                </div>
                <div className="flex gap-3">
                  <Globe2 className="w-5 h-5 text-muted-foreground" />
                  <p className="text-sm">Speaks Chinese, Dutch, and English</p>
                </div>
                <div className="flex gap-3">
                  <Globe2 className="w-5 h-5 text-muted-foreground" />
                  <p className="text-sm">Lives in Amsterdam, The Netherlands</p>
                </div>
              </div>

              {/* Host Description */}
              <p className="text-sm">
                We are a family of 2 kids and 2 creative entrepreneurs, working at home with few...
              </p>

              <Button variant="link" className="p-0 h-auto text-sm">
                Show more
              </Button>
            </div>
          </CardContent>
        </Card>

        {/* Host Details Section */}
        <div className="space-y-6">
          {/* Superhost Information */}
          <div>
            <h2 className="text-xl font-semibold mb-2">Rob & Fang is a Superhost</h2>
            <p className="text-sm text-muted-foreground">
              Superhosts are experienced, highly rated hosts who are committed to providing great stays for guests.
            </p>
          </div>

          {/* Additional Host Details */}
          <div>
            <h3 className="text-lg font-semibold mb-4">Host details</h3>
            <div className="space-y-2">
              <p className="text-sm">Response rate: 100%</p>
              <p className="text-sm">Responds within an hour</p>
            </div>
          </div>

          {/* Message Host Button */}
          <Button className="w-[150px]" size="lg">
            Message Host
          </Button>

          {/* Registration and Payment Protection Notice */}
          <div className="space-y-4">
            <p className="text-sm text-muted-foreground">
              Registration number: 0363 FC71 F7DB 3AB4 3C42
            </p>
            <div className="flex gap-2 items-start text-sm text-muted-foreground">
              <Shield className="w-5 h-5 mt-0.5" />
              <p>
                To protect your payment, never transfer money or communicate outside of the Airbnb website or app.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
  );
}

