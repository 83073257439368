'use client'

import { ChevronLeft, ChevronRight, Grid } from 'lucide-react'
import { useState } from 'react'
import { Button } from './ui/button'


interface ImageSliderProps {
  images: Array<{
    src: string
    alt: string
  }>
  onShowAllPhotos: () => void
}

export function ImageSlider({ images, onShowAllPhotos }: ImageSliderProps) {
  const [currentIndex, setCurrentIndex] = useState(0)

  const showPreviousImage = () => {
    setCurrentIndex((index) => (index === 0 ? images.length - 1 : index - 1))
  }

  const showNextImage = () => {
    setCurrentIndex((index) => (index === images.length - 1 ? 0 : index + 1))
  }

  return (
    <div className="relative w-[100%] h-[300px] md:h-[400px]">
      <img
        src={images[currentIndex].src}
        alt={images[currentIndex].alt}
        className="w-full h-full object-cover"
      />
      <div className="absolute inset-0 flex items-center justify-between p-4">
        <Button
          variant="secondary"
          size="icon"
          className="h-8 w-8 bg-white/70 hover:bg-white/90"
          onClick={showPreviousImage}
        >
          <ChevronLeft className="h-4 w-4" />
        </Button>
        <Button
          variant="secondary"
          size="icon"
          className="h-8 w-8 bg-white/70 hover:bg-white/90"
          onClick={showNextImage}
        >
          <ChevronRight className="h-4 w-4" />
        </Button>
      </div>
      <div className="absolute bottom-4 left-4 bg-white/70 px-2 py-1 rounded-full text-sm">
        {currentIndex + 1} / {images.length}
      </div>
      <Button 
        variant="secondary" 
        className="absolute bottom-4 right-4 bg-white hover:bg-white/90"
        onClick={onShowAllPhotos}
      >
        <Grid className="mr-2 h-4 w-4" />
        Show all photos
      </Button>
    </div>
  )
}

