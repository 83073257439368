// "use client"

// import { useState } from "react"
// import { Card, CardContent, CardHeader } from "../ui/card"
// import { Button } from "../ui/button"
// import { DatePicker } from "./datePicker"
// import { GuestSelector } from "./guestSelector"

// export function BookingCard() {
//   const [startDate, setStartDate] = useState(null)
//   const [endDate, setEndDate] = useState(null)
//   const [isCalendarOpen, setIsCalendarOpen] = useState(false)
//   const [guests, setGuests] = useState({ adults: 2, children: 0, infants: 0 })

//   const handleDateChange = ({ startDate: newStartDate, endDate: newEndDate }) => {
//     setStartDate(newStartDate)
//     setEndDate(newEndDate)
//   }

//   const calculateTotalNights = () => {
//     if (startDate && endDate) {
//       return Math.ceil((endDate - startDate) / (1000 * 60 * 60 * 24))
//     }
//     return 0
//   }

//   const nightlyRate = 122
//   const totalNights = calculateTotalNights()
//   const subtotal = nightlyRate * totalNights
//   const weeklyDiscount = totalNights >= 7 ? 77 : 0
//   const cleaningFee = 37
//   const serviceFee = Math.round(subtotal * 0.15)
//   const total = subtotal - weeklyDiscount + cleaningFee + serviceFee

//   return (
//     <Card className="w-full max-w-md mx-auto">
//       <CardHeader>
//         <div className="flex items-baseline gap-2">
//           <span className="text-2xl font-bold">${nightlyRate}</span>
//           <span className="text-muted-foreground">night</span>
//         </div>
//       </CardHeader>
//       <CardContent className="space-y-4">
//         <DatePicker
//           startDate={startDate}
//           endDate={endDate}
//           onDateChange={handleDateChange}
//           open={isCalendarOpen}
//           onOpenChange={setIsCalendarOpen}
//         />

//         <GuestSelector
//           value={guests}
//           onChange={setGuests}
//           maxGuests={2}
//           //@ts-ignore
//           disabled={!startDate || !endDate}
//         />

//         <Button 
//           className="w-full bg-[#FF385C] hover:bg-[#FF385C]/90 text-white"
//           onClick={() => !startDate && setIsCalendarOpen(true)}
//         >
//           {startDate && endDate ? "Check availability" : "Check availability"}
//         </Button>

//         {startDate && endDate && (
//           <>
//             <div className="space-y-2">
//               <div className="flex justify-between">
//                 <span className="underline">
//                   ${nightlyRate} x {totalNights} nights
//                 </span>
//                 <span>${subtotal}</span>
//               </div>
//               {weeklyDiscount > 0 && (
//                 <div className="flex justify-between text-green-600">
//                   <span>Weekly stay discount</span>
//                   <span>-${weeklyDiscount}</span>
//                 </div>
//               )}
//               <div className="flex justify-between">
//                 <span className="underline">Cleaning fee</span>
//                 <span>${cleaningFee}</span>
//               </div>
//               <div className="flex justify-between">
//                 <span className="underline">Airbnb service fee</span>
//                 <span>${serviceFee}</span>
//               </div>
//               <div className="pt-4 border-t flex justify-between font-semibold">
//                 <span>Total before taxes</span>
//                 <span>${total}</span>
//               </div>
//             </div>
//             <p className="text-center text-sm text-muted-foreground">
//               You won't be charged yet
//             </p>
//           </>
//         )}
//       </CardContent>
//     </Card>
//   )
// }

// "use client"

// import { useState } from "react"
// import { Card, CardContent, CardHeader } from "../ui/card"
// import { Button } from "../ui/button"
// import { DatePicker } from "./datePicker"
// import { GuestSelector } from "./guestSelector"
// import { RatesSelector } from "./ratesSelector"

// export function BookingCard() {
//   const [startDate, setStartDate] = useState(null)
//   const [endDate, setEndDate] = useState(null)
//   const [isCalendarOpen, setIsCalendarOpen] = useState(false)
//   const [guests, setGuests] = useState({ adults: 2, children: 0, infants: 0 })
//   const [selectedRate, setSelectedRate] = useState("non-refundable")

//   const handleDateChange = ({ startDate: newStartDate, endDate: newEndDate }) => {
//     setStartDate(newStartDate)
//     setEndDate(newEndDate)
//   }

//   const calculateTotalNights = () => {
//     if (startDate && endDate) {
//       return Math.ceil((endDate - startDate) / (1000 * 60 * 60 * 24))
//     }
//     return 0
//   }

//   const nightlyRate = 122
//   const totalNights = calculateTotalNights()
//   const subtotal = nightlyRate * totalNights
//   const weeklyDiscount = totalNights >= 7 ? 77 : 0
//   const cleaningFee = 37
//   const serviceFee = Math.round(subtotal * 0.15)
//   const total = subtotal - weeklyDiscount + cleaningFee + serviceFee

//   return (
//     <Card className="w-full max-w-md mx-auto">
//       <CardHeader>
//         <div className="flex items-baseline gap-2">
//           <span className="text-2xl font-bold">${nightlyRate}</span>
//           <span className="text-muted-foreground">night</span>
//         </div>
//       </CardHeader>
//       <CardContent className="space-y-4">
//         <DatePicker
//           startDate={startDate}
//           endDate={endDate}
//           onDateChange={handleDateChange}
//           open={isCalendarOpen}
//           onOpenChange={setIsCalendarOpen}
//         />

//         <GuestSelector
//           value={guests}
//           onChange={setGuests}
//           maxGuests={2}
//           //@ts-ignore
//           disabled={!startDate || !endDate}
//         />

//         <Button 
//           className="w-full bg-[#FF385C] hover:bg-[#FF385C]/90 text-white"
//           onClick={() => !startDate && setIsCalendarOpen(true)}
//         >
//           {startDate && endDate ? "Check availability" : "Check availability"}
//         </Button>

//         {startDate && endDate && (
//           <>
//             <div className="space-y-2">
//               <div className="flex justify-between">
//                 <span className="underline">
//                   ${nightlyRate} x {totalNights} nights
//                 </span>
//                 <span>${subtotal}</span>
//               </div>
//               {weeklyDiscount > 0 && (
//                 <div className="flex justify-between text-green-600">
//                   <span>Weekly stay discount</span>
//                   <span>-${weeklyDiscount}</span>
//                 </div>
//               )}
//               <div className="flex justify-between">
//                 <span className="underline">Cleaning fee</span>
//                 <span>${cleaningFee}</span>
//               </div>
//               <div className="flex justify-between">
//                 <span className="underline">Airbnb service fee</span>
//                 <span>${serviceFee}</span>
//               </div>
//               <div className="pt-4 border-t flex justify-between font-semibold">
//                 <span>Total before taxes</span>
//                 <span>${total}</span>
//               </div>
//             </div>

//             <RatesSelector 
//               selectedRate={selectedRate}
//               onSelect={setSelectedRate}
//             />

//             <Button 
//               className="w-full bg-[#FF385C] hover:bg-[#FF385C]/90 text-white"
//             >
//               Reserve
//             </Button>

//             <div className="space-y-2 text-center">
//               <p className="text-sm text-muted-foreground">
//                 You won't be charged yet
//               </p>
//               <p className="text-sm text-muted-foreground">
//                 Price per night includes VAT and all applicable fees.
//               </p>
//             </div>
//           </>
//         )}
//       </CardContent>
//     </Card>
//   )
// }

"use client"

import { useState } from "react"
import { Card, CardContent, CardHeader } from "../ui/card"
import { Button } from "../ui/button"
import { Input } from "../ui/input"
import { DatePicker } from "./datePicker"
import { GuestSelector } from "./guestSelector"
import { RatesSelector } from "./ratesSelector"
//@ts-ignore
import { useRecoilState, useRecoilValue, useResetRecoilState } from "recoil"
import { discountedPrice,  totalAmount, inDate, outDate, totalGuests, totalNightNumber, cleaningCharge, serviceCharge, subAmount, nightRate, startRecoilDate,endRecoilDate,isRecoilCalendarOpen } from "../../recoil/atom";
import { useNavigate } from "react-router-dom"

export function BookingCard() {
  const [startDate, setStartDate] = useRecoilState(startRecoilDate)
  const [endDate, setEndDate] = useRecoilState(endRecoilDate)
  const [isCalendarOpen, setIsCalendarOpen] = useRecoilState(isRecoilCalendarOpen)
  const [guests, setGuests] = useState({ adults: 2, children: 0, infants: 0 })
  const [selectedRate, setSelectedRate] = useState("non-refundable")
  const [discountCode, setDiscountCode] = useState("")
  const [isDiscountApplied, setIsDiscountApplied] = useState(false)
  const navigate = useNavigate()
  //recoil stuff
  const [amount,setAmount] = useRecoilState(totalAmount)
  const [discount,setDiscount] = useRecoilState(discountedPrice)
  const [saveStartDate,setSaveStartDate] = useRecoilState(inDate)
  const [saveEndDate, setSaveEndDate] = useRecoilState(outDate)
  const [recoilGuests, setRecoilGuests] = useRecoilState(totalGuests)
  const [Nights,setNights] = useRecoilState(totalNightNumber)
  const [cleaningCost,setCleaningCost] = useRecoilState(cleaningCharge)
  const [serviceCost,setServiceCost] = useRecoilState(serviceCharge)
  const [subTotalAmount,setSubTotalAmount] = useRecoilState(subAmount)
  const [oneNight,setOneNight] = useRecoilState(nightRate)

  const handleDateChange = ({ startDate: newStartDate, endDate: newEndDate }) => {
    setStartDate(newStartDate)
    setEndDate(newEndDate)
    setSaveStartDate(newStartDate)
    setSaveEndDate(newEndDate)
  }

  const calculateTotalNights = () => {
    if (startDate && endDate) {
      return Math.ceil((endDate - startDate) / (1000 * 60 * 60 * 24))
    }
    return 0
  }

  const nightlyRate = 122
  setOneNight(nightlyRate)
  const totalNights = calculateTotalNights()
  const subtotal = nightlyRate * totalNights
  const weeklyDiscount = totalNights >= 7 ? 77 : 0
  const cleaningFee = 37
  const serviceFee = Math.round(subtotal * 0.15)
  
  const applyDiscount = () => {
    if (discountCode === "Ari45834" && !isDiscountApplied) {
      setIsDiscountApplied(true)
    }
  }

  const discountAmount = isDiscountApplied ? Math.round(subtotal * 0.15) : 0
  let total = subtotal - weeklyDiscount - discountAmount + cleaningFee + serviceFee
  setAmount(total)
  setDiscount(weeklyDiscount+discountAmount)
  console.log(startDate)
  console.log(endDate)
  setRecoilGuests(guests.adults+guests.children+guests.infants)
  setNights(totalNights)
  setCleaningCost(cleaningFee)
  setServiceCost(serviceFee)
  setSubTotalAmount(subtotal)


  return (
    <Card className="w-full max-w-md mx-auto">
      <CardHeader>
        <div className="flex items-baseline gap-2">
          <span className="text-2xl font-bold">${nightlyRate}</span>
          <span className="text-muted-foreground">night</span>
        </div>
      </CardHeader>
      <CardContent className="space-y-4">
        <DatePicker
          startDate={startDate}
          endDate={endDate}
          onDateChange={handleDateChange}
          open={isCalendarOpen}
          onOpenChange={setIsCalendarOpen}
        />

        <GuestSelector
          value={guests}
          onChange={setGuests}
          maxGuests={2}
          //@ts-ignore
          disabled={!startDate || !endDate}
        />

        <Button 
          className="w-full bg-[#FF385C] hover:bg-[#FF385C]/90 text-white"
          onClick={() => !startDate && setIsCalendarOpen(true)}
        >
          {startDate && endDate ? "Check availability" : "Check availability"}
        </Button>

        {startDate && endDate && (
          <>
            <div className="space-y-2">
              <div className="flex justify-between">
                <span className="underline">
                  ${nightlyRate} x {totalNights} nights
                </span>
                <span>${subtotal}</span>
              </div>
              {weeklyDiscount > 0 && (
                <div className="flex justify-between text-green-600">
                  <span>Weekly stay discount</span>
                  <span>-${weeklyDiscount}</span>
                </div>
              )}
              {isDiscountApplied && (
                <div className="flex justify-between text-green-600">
                  <span>Discount (15%)</span>
                  <span>-${discountAmount}</span>
                </div>
              )}
              <div className="flex justify-between">
                <span className="underline">Cleaning fee</span>
                <span>${cleaningFee}</span>
              </div>
              <div className="flex justify-between">
                <span className="underline">Airbnb service fee</span>
                <span>${serviceFee}</span>
              </div>
              <div className="pt-4 border-t flex justify-between font-semibold">
                <span>Total before taxes</span>
                <span>${total}</span>
              </div>
            </div>

            <RatesSelector 
              selectedRate={selectedRate}
              onSelect={setSelectedRate}
            />

            <div className="space-y-2">
              <Input
                type="text"
                placeholder="Discount code"
                value={discountCode}
                onChange={(e) => setDiscountCode(e.target.value)}
              />
              <Button 
                className="w-full"
                onClick={applyDiscount}
                disabled={isDiscountApplied}
              >
                Apply Discount
              </Button>
            </div>

            <Button 
            onClick={()=>{
              navigate('/confirm-reservation')
            }}
              className="w-full bg-[#FF385C] hover:bg-[#FF385C]/90 text-white"
            >
              Reserve
            </Button>

            <div className="space-y-2 text-center">
              <p className="text-sm text-muted-foreground">
                You won't be charged yet
              </p>
              <p className="text-sm text-muted-foreground">
                Price per night includes VAT and all applicable fees.
              </p>
            </div>
          </>
        )}
      </CardContent>
    </Card>
  )
}



