import { format } from "date-fns";
import { useMemo, useState } from "react";
import { FaSearch } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { useDebounceValue } from "usehooks-ts";
import { BASE_URL_FLAG } from "../lib/constants";
import { COUNTRIES } from "../lib/countries";
import { countries } from "../lib/data";
import { ICountryData } from "../lib/interfaces";
import { SelectMenuOption } from "../lib/types";
import CountrySelector from "./selector";
import { Avatar, AvatarFallback, AvatarImage } from "./ui/avatar";
import { Button } from "./ui/button";
import { Card, CardContent } from "./ui/card";
import { Input } from "./ui/input";
import { Label } from "./ui/label";
import PhoneInput from "./ui/phone-input";
import { Textarea } from "./ui/textarea";
import { inDate,outDate,totalGuests } from "../recoil/atom";
import { useRecoilValue } from "recoil";

// interface ReservationFormProps {
//   onSubmit: (formData: FormData) => void;
//   // isSubmitting: boolean;
//   dates: { from: Date; to: Date };
//   guests: string;
// }

export function ReservationForm() {
  const navigate = useNavigate();

  const [isOpen, setIsOpen] = useState(false);
  const [country, setCountry] = useState<SelectMenuOption["value"]>("BE");
  const [opened, setOpened] = useState<boolean>(false);
  const [phoneCountry, setPhoneCountry] = useState<ICountryData>(countries[0]);
  const [searchKey, setSearchKey] = useState<string>("");
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [debouncedSearchKey] = useDebounceValue<string>(searchKey, 500);
  const [title,setTitle] = useState("")
  const [content,setContent] = useState("")

  const selectableCountries = useMemo<Array<ICountryData>>(() => {
    if (debouncedSearchKey && debouncedSearchKey[0] !== "+") {
      const regExp = new RegExp(debouncedSearchKey);
      return countries.filter(
        (item) =>
          item.name.toLowerCase().match(regExp) ||
          item.dial_code.toLowerCase().match(regExp)
      );
    }
    return countries;
  }, [debouncedSearchKey]);

  const toggleOpened = () => {
    setOpened(!opened);
  };

  const selectCountry = (item: ICountryData) => {
    setPhoneCountry(item);
    toggleOpened();
  };

  const onAcceptPhoneNumber = (value: string) => {
    setPhoneNumber(value);
  };

  const submit = (e: any) => {
    
  }
  const startDate = useRecoilValue(inDate)
  const endDate  = useRecoilValue(outDate)
  const Guests = useRecoilValue(totalGuests)

  console.log(title)

  const handleEmail = ()=>{
    window.location.href = `mailto:alexgerd054@gmail.com?subject=${`about business`}&body=${title}`;
  }

  return (
    <form
      onSubmit={(e) => submit(e)}
      className="space-y-8"
    >
      <div className="space-y-4">
        <h2 className="text-xl font-semibold">Your trip</h2>
        <Card>
          <CardContent className="p-4">
            <div className="flex justify-between items-center py-2">
              <div>
                <div className="font-medium">Dates</div>
                <div className="text-sm text-muted-foreground">
                  {format(
                    new Date(startDate),
                    // dates.from
                    "MMM d, yyyy"
                  )}{" "}
                  -{" "}
                  {format(
                    new Date(endDate),
                    // dates.to
                    "MMM d, yyyy"
                  )}
                </div>
              </div>
              {/* <Button variant="link" className="h-auto p-0">
                Edit
              </Button> */}
            </div>
            <div className="flex justify-between items-center py-2">
              <div>
                <div className="font-medium">Guests</div>
                <div className="text-sm text-muted-foreground">
                  {
                    Guests
                    } {
                      Guests
                       === 1 ? 'guest' : 'guests'
                  }
                </div>
              </div>
              {/* <Button variant="link" className="h-auto p-0">
                Edit
              </Button> */}
            </div>
          </CardContent>
        </Card>
      </div>

      <div className="divider"></div>

      <div className="space-y-4">
        <div className="space-y-2">
          <Label htmlFor="name">Name</Label>
          <Input id="name" name="name" required />
        </div>

        <div className="space-y-2">
          <Label htmlFor="email">Email</Label>
          <Input id="email" name="email" required />
        </div>

        <div className="flex items-stretch gap-2">
          {/* Country select */}
          <div className="relative">
            <div
              className="min-w-24 lg:min-w-32 border border-gray-200 hover:border-black transition rounded-xl py-3 px-3 flex items-center gap-2 cursor-pointer"
              onClick={toggleOpened}
            >
              <img
                src={`${BASE_URL_FLAG}/${phoneCountry.code.toLowerCase()}.svg`}
                alt={phoneCountry.code}
                className="w-6 lg:w-7"
              />
              <span>{phoneCountry.dial_code}</span>
            </div>

            {opened && (
              <div className="absolute top-[100%] shadow-md">
                <div className="relative min-w-80 lg:min-w-96 max-h-60 overflow-auto bg-white">
                  {/* Search field */}
                  <div className="sticky top-0  border-b border-gray-300 bg-white">
                    <div className="flex items-center gap-2 p-3">
                      <input
                        className="bg-transparent outline-none flex-1"
                        placeholder="Search"
                        value={searchKey}
                        onChange={(e) => setSearchKey(e.target.value)}
                      />
                      <FaSearch className="text-gray-400" />
                    </div>
                  </div>

                  {/* Country list */}
                  <div className="px-2 py-1 flex flex-col gap-1">
                    {selectableCountries.map((item) => (
                      <div
                        key={item.code}
                        className="p-2 hover:bg-gray-100 flex items-center gap-4 cursor-pointer active:bg-blue-500 active:text-gray-100"
                        onClick={() => selectCountry(item)}
                      >
                        <div className="flex items-center gap-2">
                          <img
                            src={`${BASE_URL_FLAG}/${item.code.toLowerCase()}.svg`}
                            alt={item.code}
                            width={28}
                          />

                          <span className="text-gray-400">
                            {item.dial_code}
                          </span>
                        </div>

                        <span>{item.name}</span>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            )}
          </div>

          {/* Phone number input */}
          <PhoneInput
            type="text"
            name="phone"
            className="flex-1"
            placeholder="Phone number"
            onAccept={onAcceptPhoneNumber}
            required
          />
        </div>

        <h3 className="font-medium pt-4">Billing address</h3>
        <div className="space-y-4">
          <div className="space-y-2">
            <Input
              id="street"
              name="street"
              placeholder="Street address"
              required
            />
            <Input
              id="apt"
              name="apt"
              placeholder="Apt or suite number"
              required
            />
            <Input id="city" name="city" placeholder="City" required />
          </div>
          <div className="grid grid-cols-2 gap-1">
            <Input id="state" name="state" placeholder="State" required />
            <Input id="zip" name="zip" placeholder="Zip code" required />
          </div>
          <CountrySelector
            id={"country-selector"}
            open={isOpen}
            onToggle={() => setIsOpen(!isOpen)}
            onChange={setCountry}
            selectedValue={COUNTRIES.find((option) => option.value === country)}
          />
        </div>
      </div>

      {/* Message Host */}
      <div className="space-y-4 py-8">
        <h2 className="text-xl font-semibold">Message the host</h2>
        <p className="text-sm">
          Share why you're traveling, who's coming with you, and what you love
          about the space.
        </p>

        <Card>
          <CardContent className="p-4">
            <div className="flex gap-4 mb-4">
              <Avatar>
                <AvatarImage
                  src="/avatar1.jpg?height=40&width=40"
                  alt="Rob & Fang"
                />
                <AvatarFallback>RF</AvatarFallback>
              </Avatar>
              <div className="flex-1">
                <div className="font-medium">Rob & Fang</div>
                <div className="text-sm text-muted-foreground">
                  Joined in 2012
                </div>
              </div>
            </div>

            <div className="space-y-4">
              <p className="text-sm">
                Thanks for your interest in AmstNest!
                <br />
                We'll get in touch soon about public transportation etc. Feel
                free to contact us for any questions :)
                <br />
                <br />
                Greetings,
                <br />
                Rob and Fang
              </p>
              <Textarea
                placeholder="Hi Rob & Fang! I'll be visiting..."
                className="min-h-[100px]"
                onChange={(e:any)=>{setTitle(e.target.value)}}
              />
              <div className="flex justify-end">
                <button className="py-2 px-5 bg-blue-500 text-white rounded-sm"
                onClick={handleEmail}
                >
                  Send
                </button>
              </div>
            </div>
          </CardContent>
        </Card>
      </div>

      {/* Policy Section */}
      <div className="space-y-8 py-8">
        <div className="space-y-4">
          <h2 className="text-xl font-semibold">Cancellation policy</h2>
          <Card>
            <CardContent className="p-4">
              <p className="text-sm">
                <b>Free cancellation before Jan 5.</b> After that, the
                reservation is non-refundable.
              </p>
              <Button variant="link" className="h-auto p-0 text-sm underline">
                Learn more
              </Button>
            </CardContent>
          </Card>
        </div>

        <div className="space-y-4">
          <h2 className="text-xl font-semibold">Ground rules</h2>
          <Card>
            <CardContent className="p-4">
              <p className="text-sm">
                We ask every guest to remember a few simple things about what
                makes a great guest.
              </p>
              <ul className="list-disc pl-4 mt-2 text-sm">
                <li>Follow the house rules</li>
                <li>Treat your Host's home like your own</li>
              </ul>
            </CardContent>
          </Card>
        </div>

        <div className="text-sm">
          By selecting the button below, I agree to the Host's House Rules,
          Ground rules for guests, Airbnb's Rebooking and Refund Policy, and
          that Airbnb can charge my payment method if I'm responsible for
          damage. Payment Terms between you and Airbnb Payments Luxembourg S.A.
        </div>

        <Button 
        // type="submit"
        onClick={()=>{
          navigate('/payment-page')
        }}
         className="bg-red-500 hover:bg-red-700" size="lg">
          Confirm reservation
        </Button>
      </div>
    </form>
  );
}




