import { BASE_URL_FLAG } from "./constants";
import { ICountryData, ILangData } from "./interfaces";

export const languages: Array<ILangData> = [
  {
    id: "en",
    label: "English (United States)",
    flagSrc: `${BASE_URL_FLAG}/us.svg`,
  },
  {
    id: "de",
    label: "German",
    flagSrc: `${BASE_URL_FLAG}/de.svg`,
  },
  {
    id: "ru",
    label: "Russian",
    flagSrc: `${BASE_URL_FLAG}/ru.svg`,
  },
];

export const countries: Array<ICountryData> = [
  {
    name: "Afghanistan",
    dial_code: "+93",
    code: "AF",
  },
  {
    name: "Aland Islands",
    dial_code: "+358",
    code: "AX",
  },
  {
    name: "Albania",
    dial_code: "+355",
    code: "AL",
  },
  {
    name: "Algeria",
    dial_code: "+213",
    code: "DZ",
  },
  {
    name: "AmericanSamoa",
    dial_code: "+1684",
    code: "AS",
  },
  {
    name: "Andorra",
    dial_code: "+376",
    code: "AD",
  },
  {
    name: "Angola",
    dial_code: "+244",
    code: "AO",
  },
  {
    name: "Anguilla",
    dial_code: "+1264",
    code: "AI",
  },
  {
    name: "Antarctica",
    dial_code: "+672",
    code: "AQ",
  },
  {
    name: "Antigua and Barbuda",
    dial_code: "+1268",
    code: "AG",
  },
  {
    name: "Argentina",
    dial_code: "+54",
    code: "AR",
  },
  {
    name: "Armenia",
    dial_code: "+374",
    code: "AM",
  },
  {
    name: "Aruba",
    dial_code: "+297",
    code: "AW",
  },
  {
    name: "Australia",
    dial_code: "+61",
    code: "AU",
  },
  {
    name: "Austria",
    dial_code: "+43",
    code: "AT",
  },
  {
    name: "Azerbaijan",
    dial_code: "+994",
    code: "AZ",
  },
  {
    name: "Bahamas",
    dial_code: "+1242",
    code: "BS",
  },
  {
    name: "Bahrain",
    dial_code: "+973",
    code: "BH",
  },
  {
    name: "Bangladesh",
    dial_code: "+880",
    code: "BD",
  },
  {
    name: "Barbados",
    dial_code: "+1246",
    code: "BB",
  },
  {
    name: "Belarus",
    dial_code: "+375",
    code: "BY",
  },
  {
    name: "Belgium",
    dial_code: "+32",
    code: "BE",
  },
  {
    name: "Belize",
    dial_code: "+501",
    code: "BZ",
  },
  {
    name: "Benin",
    dial_code: "+229",
    code: "BJ",
  },
  {
    name: "Bermuda",
    dial_code: "+1441",
    code: "BM",
  },
  {
    name: "Bhutan",
    dial_code: "+975",
    code: "BT",
  },
  {
    name: "Bolivia, Plurinational State of",
    dial_code: "+591",
    code: "BO",
  },
  {
    name: "Bosnia and Herzegovina",
    dial_code: "+387",
    code: "BA",
  },
  {
    name: "Botswana",
    dial_code: "+267",
    code: "BW",
  },
  {
    name: "Brazil",
    dial_code: "+55",
    code: "BR",
  },
  {
    name: "British Indian Ocean Territory",
    dial_code: "+246",
    code: "IO",
  },
  {
    name: "Brunei Darussalam",
    dial_code: "+673",
    code: "BN",
  },
  {
    name: "Bulgaria",
    dial_code: "+359",
    code: "BG",
  },
  {
    name: "Burkina Faso",
    dial_code: "+226",
    code: "BF",
  },
  {
    name: "Burundi",
    dial_code: "+257",
    code: "BI",
  },
  {
    name: "Cambodia",
    dial_code: "+855",
    code: "KH",
  },
  {
    name: "Cameroon",
    dial_code: "+237",
    code: "CM",
  },
  {
    name: "Canada",
    dial_code: "+1",
    code: "CA",
  },
  {
    name: "Cape Verde",
    dial_code: "+238",
    code: "CV",
  },
  {
    name: "Cayman Islands",
    dial_code: "+ 345",
    code: "KY",
  },
  {
    name: "Central African Republic",
    dial_code: "+236",
    code: "CF",
  },
  {
    name: "Chad",
    dial_code: "+235",
    code: "TD",
  },
  {
    name: "Chile",
    dial_code: "+56",
    code: "CL",
  },
  {
    name: "China",
    dial_code: "+86",
    code: "CN",
  },
  {
    name: "Christmas Island",
    dial_code: "+61",
    code: "CX",
  },
  {
    name: "Cocos (Keeling) Islands",
    dial_code: "+61",
    code: "CC",
  },
  {
    name: "Colombia",
    dial_code: "+57",
    code: "CO",
  },
  {
    name: "Comoros",
    dial_code: "+269",
    code: "KM",
  },
  {
    name: "Congo",
    dial_code: "+242",
    code: "CG",
  },
  {
    name: "Congo, The Democratic Republic of the Congo",
    dial_code: "+243",
    code: "CD",
  },
  {
    name: "Cook Islands",
    dial_code: "+682",
    code: "CK",
  },
  {
    name: "Costa Rica",
    dial_code: "+506",
    code: "CR",
  },
  {
    name: "Cote d'Ivoire",
    dial_code: "+225",
    code: "CI",
  },
  {
    name: "Croatia",
    dial_code: "+385",
    code: "HR",
  },
  {
    name: "Cuba",
    dial_code: "+53",
    code: "CU",
  },
  {
    name: "Cyprus",
    dial_code: "+357",
    code: "CY",
  },
  {
    name: "Czech Republic",
    dial_code: "+420",
    code: "CZ",
  },
  {
    name: "Denmark",
    dial_code: "+45",
    code: "DK",
  },
  {
    name: "Djibouti",
    dial_code: "+253",
    code: "DJ",
  },
  {
    name: "Dominica",
    dial_code: "+1767",
    code: "DM",
  },
  {
    name: "Dominican Republic",
    dial_code: "+1849",
    code: "DO",
  },
  {
    name: "Ecuador",
    dial_code: "+593",
    code: "EC",
  },
  {
    name: "Egypt",
    dial_code: "+20",
    code: "EG",
  },
  {
    name: "El Salvador",
    dial_code: "+503",
    code: "SV",
  },
  {
    name: "Equatorial Guinea",
    dial_code: "+240",
    code: "GQ",
  },
  {
    name: "Eritrea",
    dial_code: "+291",
    code: "ER",
  },
  {
    name: "Estonia",
    dial_code: "+372",
    code: "EE",
  },
  {
    name: "Ethiopia",
    dial_code: "+251",
    code: "ET",
  },
  {
    name: "Falkland Islands (Malvinas)",
    dial_code: "+500",
    code: "FK",
  },
  {
    name: "Faroe Islands",
    dial_code: "+298",
    code: "FO",
  },
  {
    name: "Fiji",
    dial_code: "+679",
    code: "FJ",
  },
  {
    name: "Finland",
    dial_code: "+358",
    code: "FI",
  },
  {
    name: "France",
    dial_code: "+33",
    code: "FR",
  },
  {
    name: "French Guiana",
    dial_code: "+594",
    code: "GF",
  },
  {
    name: "French Polynesia",
    dial_code: "+689",
    code: "PF",
  },
  {
    name: "Gabon",
    dial_code: "+241",
    code: "GA",
  },
  {
    name: "Gambia",
    dial_code: "+220",
    code: "GM",
  },
  {
    name: "Georgia",
    dial_code: "+995",
    code: "GE",
  },
  {
    name: "Germany",
    dial_code: "+49",
    code: "DE",
  },
  {
    name: "Ghana",
    dial_code: "+233",
    code: "GH",
  },
  {
    name: "Gibraltar",
    dial_code: "+350",
    code: "GI",
  },
  {
    name: "Greece",
    dial_code: "+30",
    code: "GR",
  },
  {
    name: "Greenland",
    dial_code: "+299",
    code: "GL",
  },
  {
    name: "Grenada",
    dial_code: "+1473",
    code: "GD",
  },
  {
    name: "Guadeloupe",
    dial_code: "+590",
    code: "GP",
  },
  {
    name: "Guam",
    dial_code: "+1671",
    code: "GU",
  },
  {
    name: "Guatemala",
    dial_code: "+502",
    code: "GT",
  },
  {
    name: "Guernsey",
    dial_code: "+44",
    code: "GG",
  },
  {
    name: "Guinea",
    dial_code: "+224",
    code: "GN",
  },
  {
    name: "Guinea-Bissau",
    dial_code: "+245",
    code: "GW",
  },
  {
    name: "Guyana",
    dial_code: "+595",
    code: "GY",
  },
  {
    name: "Haiti",
    dial_code: "+509",
    code: "HT",
  },
  {
    name: "Holy See (Vatican City State)",
    dial_code: "+379",
    code: "VA",
  },
  {
    name: "Honduras",
    dial_code: "+504",
    code: "HN",
  },
  {
    name: "Hong Kong",
    dial_code: "+852",
    code: "HK",
  },
  {
    name: "Hungary",
    dial_code: "+36",
    code: "HU",
  },
  {
    name: "Iceland",
    dial_code: "+354",
    code: "IS",
  },
  {
    name: "India",
    dial_code: "+91",
    code: "IN",
  },
  {
    name: "Indonesia",
    dial_code: "+62",
    code: "ID",
  },
  {
    name: "Iran, Islamic Republic of Persian Gulf",
    dial_code: "+98",
    code: "IR",
  },
  {
    name: "Iraq",
    dial_code: "+964",
    code: "IQ",
  },
  {
    name: "Ireland",
    dial_code: "+353",
    code: "IE",
  },
  {
    name: "Isle of Man",
    dial_code: "+44",
    code: "IM",
  },
  {
    name: "Israel",
    dial_code: "+972",
    code: "IL",
  },
  {
    name: "Italy",
    dial_code: "+39",
    code: "IT",
  },
  {
    name: "Jamaica",
    dial_code: "+1876",
    code: "JM",
  },
  {
    name: "Japan",
    dial_code: "+81",
    code: "JP",
  },
  {
    name: "Jersey",
    dial_code: "+44",
    code: "JE",
  },
  {
    name: "Jordan",
    dial_code: "+962",
    code: "JO",
  },
  {
    name: "Kazakhstan",
    dial_code: "+77",
    code: "KZ",
  },
  {
    name: "Kenya",
    dial_code: "+254",
    code: "KE",
  },
  {
    name: "Kiribati",
    dial_code: "+686",
    code: "KI",
  },
  {
    name: "Korea, Democratic People's Republic of Korea",
    dial_code: "+850",
    code: "KP",
  },
  {
    name: "Korea, Republic of South Korea",
    dial_code: "+82",
    code: "KR",
  },
  {
    name: "Kuwait",
    dial_code: "+965",
    code: "KW",
  },
  {
    name: "Kyrgyzstan",
    dial_code: "+996",
    code: "KG",
  },
  {
    name: "Laos",
    dial_code: "+856",
    code: "LA",
  },
  {
    name: "Latvia",
    dial_code: "+371",
    code: "LV",
  },
  {
    name: "Lebanon",
    dial_code: "+961",
    code: "LB",
  },
  {
    name: "Lesotho",
    dial_code: "+266",
    code: "LS",
  },
  {
    name: "Liberia",
    dial_code: "+231",
    code: "LR",
  },
  {
    name: "Libyan Arab Jamahiriya",
    dial_code: "+218",
    code: "LY",
  },
  {
    name: "Liechtenstein",
    dial_code: "+423",
    code: "LI",
  },
  {
    name: "Lithuania",
    dial_code: "+370",
    code: "LT",
  },
  {
    name: "Luxembourg",
    dial_code: "+352",
    code: "LU",
  },
  {
    name: "Macao",
    dial_code: "+853",
    code: "MO",
  },
  {
    name: "Macedonia",
    dial_code: "+389",
    code: "MK",
  },
  {
    name: "Madagascar",
    dial_code: "+261",
    code: "MG",
  },
  {
    name: "Malawi",
    dial_code: "+265",
    code: "MW",
  },
  {
    name: "Malaysia",
    dial_code: "+60",
    code: "MY",
  },
  {
    name: "Maldives",
    dial_code: "+960",
    code: "MV",
  },
  {
    name: "Mali",
    dial_code: "+223",
    code: "ML",
  },
  {
    name: "Malta",
    dial_code: "+356",
    code: "MT",
  },
  {
    name: "Marshall Islands",
    dial_code: "+692",
    code: "MH",
  },
  {
    name: "Martinique",
    dial_code: "+596",
    code: "MQ",
  },
  {
    name: "Mauritania",
    dial_code: "+222",
    code: "MR",
  },
  {
    name: "Mauritius",
    dial_code: "+230",
    code: "MU",
  },
  {
    name: "Mayotte",
    dial_code: "+262",
    code: "YT",
  },
  {
    name: "Mexico",
    dial_code: "+52",
    code: "MX",
  },
  {
    name: "Micronesia, Federated States of Micronesia",
    dial_code: "+691",
    code: "FM",
  },
  {
    name: "Moldova",
    dial_code: "+373",
    code: "MD",
  },
  {
    name: "Monaco",
    dial_code: "+377",
    code: "MC",
  },
  {
    name: "Mongolia",
    dial_code: "+976",
    code: "MN",
  },
  {
    name: "Montenegro",
    dial_code: "+382",
    code: "ME",
  },
  {
    name: "Montserrat",
    dial_code: "+1664",
    code: "MS",
  },
  {
    name: "Morocco",
    dial_code: "+212",
    code: "MA",
  },
  {
    name: "Mozambique",
    dial_code: "+258",
    code: "MZ",
  },
  {
    name: "Myanmar",
    dial_code: "+95",
    code: "MM",
  },
  {
    name: "Namibia",
    dial_code: "+264",
    code: "NA",
  },
  {
    name: "Nauru",
    dial_code: "+674",
    code: "NR",
  },
  {
    name: "Nepal",
    dial_code: "+977",
    code: "NP",
  },
  {
    name: "Netherlands",
    dial_code: "+31",
    code: "NL",
  },
  {
    name: "Netherlands Antilles",
    dial_code: "+599",
    code: "AN",
  },
  {
    name: "New Caledonia",
    dial_code: "+687",
    code: "NC",
  },
  {
    name: "New Zealand",
    dial_code: "+64",
    code: "NZ",
  },
  {
    name: "Nicaragua",
    dial_code: "+505",
    code: "NI",
  },
  {
    name: "Niger",
    dial_code: "+227",
    code: "NE",
  },
  {
    name: "Nigeria",
    dial_code: "+234",
    code: "NG",
  },
  {
    name: "Niue",
    dial_code: "+683",
    code: "NU",
  },
  {
    name: "Norfolk Island",
    dial_code: "+672",
    code: "NF",
  },
  {
    name: "Northern Mariana Islands",
    dial_code: "+1670",
    code: "MP",
  },
  {
    name: "Norway",
    dial_code: "+47",
    code: "NO",
  },
  {
    name: "Oman",
    dial_code: "+968",
    code: "OM",
  },
  {
    name: "Pakistan",
    dial_code: "+92",
    code: "PK",
  },
  {
    name: "Palau",
    dial_code: "+680",
    code: "PW",
  },
  {
    name: "Palestinian Territory, Occupied",
    dial_code: "+970",
    code: "PS",
  },
  {
    name: "Panama",
    dial_code: "+507",
    code: "PA",
  },
  {
    name: "Papua New Guinea",
    dial_code: "+675",
    code: "PG",
  },
  {
    name: "Paraguay",
    dial_code: "+595",
    code: "PY",
  },
  {
    name: "Peru",
    dial_code: "+51",
    code: "PE",
  },
  {
    name: "Philippines",
    dial_code: "+63",
    code: "PH",
  },
  {
    name: "Pitcairn",
    dial_code: "+872",
    code: "PN",
  },
  {
    name: "Poland",
    dial_code: "+48",
    code: "PL",
  },
  {
    name: "Portugal",
    dial_code: "+351",
    code: "PT",
  },
  {
    name: "Puerto Rico",
    dial_code: "+1939",
    code: "PR",
  },
  {
    name: "Qatar",
    dial_code: "+974",
    code: "QA",
  },
  {
    name: "Romania",
    dial_code: "+40",
    code: "RO",
  },
  {
    name: "Russia",
    dial_code: "+7",
    code: "RU",
  },
  {
    name: "Rwanda",
    dial_code: "+250",
    code: "RW",
  },
  {
    name: "Reunion",
    dial_code: "+262",
    code: "RE",
  },
  {
    name: "Saint Barthelemy",
    dial_code: "+590",
    code: "BL",
  },
  {
    name: "Saint Helena, Ascension and Tristan Da Cunha",
    dial_code: "+290",
    code: "SH",
  },
  {
    name: "Saint Kitts and Nevis",
    dial_code: "+1869",
    code: "KN",
  },
  {
    name: "Saint Lucia",
    dial_code: "+1758",
    code: "LC",
  },
  {
    name: "Saint Martin",
    dial_code: "+590",
    code: "MF",
  },
  {
    name: "Saint Pierre and Miquelon",
    dial_code: "+508",
    code: "PM",
  },
  {
    name: "Saint Vincent and the Grenadines",
    dial_code: "+1784",
    code: "VC",
  },
  {
    name: "Samoa",
    dial_code: "+685",
    code: "WS",
  },
  {
    name: "San Marino",
    dial_code: "+378",
    code: "SM",
  },
  {
    name: "Sao Tome and Principe",
    dial_code: "+239",
    code: "ST",
  },
  {
    name: "Saudi Arabia",
    dial_code: "+966",
    code: "SA",
  },
  {
    name: "Senegal",
    dial_code: "+221",
    code: "SN",
  },
  {
    name: "Serbia",
    dial_code: "+381",
    code: "RS",
  },
  {
    name: "Seychelles",
    dial_code: "+248",
    code: "SC",
  },
  {
    name: "Sierra Leone",
    dial_code: "+232",
    code: "SL",
  },
  {
    name: "Singapore",
    dial_code: "+65",
    code: "SG",
  },
  {
    name: "Slovakia",
    dial_code: "+421",
    code: "SK",
  },
  {
    name: "Slovenia",
    dial_code: "+386",
    code: "SI",
  },
  {
    name: "Solomon Islands",
    dial_code: "+677",
    code: "SB",
  },
  {
    name: "Somalia",
    dial_code: "+252",
    code: "SO",
  },
  {
    name: "South Africa",
    dial_code: "+27",
    code: "ZA",
  },
  {
    name: "South Sudan",
    dial_code: "+211",
    code: "SS",
  },
  {
    name: "South Georgia and the South Sandwich Islands",
    dial_code: "+500",
    code: "GS",
  },
  {
    name: "Spain",
    dial_code: "+34",
    code: "ES",
  },
  {
    name: "Sri Lanka",
    dial_code: "+94",
    code: "LK",
  },
  {
    name: "Sudan",
    dial_code: "+249",
    code: "SD",
  },
  {
    name: "Suriname",
    dial_code: "+597",
    code: "SR",
  },
  {
    name: "Svalbard and Jan Mayen",
    dial_code: "+47",
    code: "SJ",
  },
  {
    name: "Swaziland",
    dial_code: "+268",
    code: "SZ",
  },
  {
    name: "Sweden",
    dial_code: "+46",
    code: "SE",
  },
  {
    name: "Switzerland",
    dial_code: "+41",
    code: "CH",
  },
  {
    name: "Syrian Arab Republic",
    dial_code: "+963",
    code: "SY",
  },
  {
    name: "Taiwan",
    dial_code: "+886",
    code: "TW",
  },
  {
    name: "Tajikistan",
    dial_code: "+992",
    code: "TJ",
  },
  {
    name: "Tanzania, United Republic of Tanzania",
    dial_code: "+255",
    code: "TZ",
  },
  {
    name: "Thailand",
    dial_code: "+66",
    code: "TH",
  },
  {
    name: "Timor-Leste",
    dial_code: "+670",
    code: "TL",
  },
  {
    name: "Togo",
    dial_code: "+228",
    code: "TG",
  },
  {
    name: "Tokelau",
    dial_code: "+690",
    code: "TK",
  },
  {
    name: "Tonga",
    dial_code: "+676",
    code: "TO",
  },
  {
    name: "Trinidad and Tobago",
    dial_code: "+1868",
    code: "TT",
  },
  {
    name: "Tunisia",
    dial_code: "+216",
    code: "TN",
  },
  {
    name: "Turkey",
    dial_code: "+90",
    code: "TR",
  },
  {
    name: "Turkmenistan",
    dial_code: "+993",
    code: "TM",
  },
  {
    name: "Turks and Caicos Islands",
    dial_code: "+1649",
    code: "TC",
  },
  {
    name: "Tuvalu",
    dial_code: "+688",
    code: "TV",
  },
  {
    name: "Uganda",
    dial_code: "+256",
    code: "UG",
  },
  {
    name: "Ukraine",
    dial_code: "+380",
    code: "UA",
  },
  {
    name: "United Arab Emirates",
    dial_code: "+971",
    code: "AE",
  },
  {
    name: "United Kingdom",
    dial_code: "+44",
    code: "GB",
  },
  {
    name: "United States",
    dial_code: "+1",
    code: "US",
  },
  {
    name: "Uruguay",
    dial_code: "+598",
    code: "UY",
  },
  {
    name: "Uzbekistan",
    dial_code: "+998",
    code: "UZ",
  },
  {
    name: "Vanuatu",
    dial_code: "+678",
    code: "VU",
  },
  {
    name: "Venezuela, Bolivarian Republic of Venezuela",
    dial_code: "+58",
    code: "VE",
  },
  {
    name: "Vietnam",
    dial_code: "+84",
    code: "VN",
  },
  {
    name: "Virgin Islands, British",
    dial_code: "+1284",
    code: "VG",
  },
  {
    name: "Virgin Islands, U.S.",
    dial_code: "+1340",
    code: "VI",
  },
  {
    name: "Wallis and Futuna",
    dial_code: "+681",
    code: "WF",
  },
  {
    name: "Yemen",
    dial_code: "+967",
    code: "YE",
  },
  {
    name: "Zambia",
    dial_code: "+260",
    code: "ZM",
  },
  {
    name: "Zimbabwe",
    dial_code: "+263",
    code: "ZW",
  },
];

export const langCodes: Array<string> = [
  "af-ZA",
  "am-ET",
  "ar-AE",
  "ar-BH",
  "ar-DZ",
  "ar-EG",
  "ar-IQ",
  "ar-JO",
  "ar-KW",
  "ar-LB",
  "ar-LY",
  "ar-MA",
  "arn-CL",
  "ar-OM",
  "ar-QA",
  "ar-SA",
  "ar-SY",
  "ar-TN",
  "ar-YE",
  "as-IN",
  "az-Cyrl-AZ",
  "az-Latn-AZ",
  "ba-RU",
  "be-BY",
  "bg-BG",
  "bn-BD",
  "bn-IN",
  "bo-CN",
  "br-FR",
  "bs-Cyrl-BA",
  "bs-Latn-BA",
  "ca-ES",
  "co-FR",
  "cs-CZ",
  "cy-GB",
  "da-DK",
  "de-AT",
  "de-CH",
  "de-DE",
  "de-LI",
  "de-LU",
  "dsb-DE",
  "dv-MV",
  "el-GR",
  "en-029",
  "en-AU",
  "en-BZ",
  "en-CA",
  "en-GB",
  "en-IE",
  "en-IN",
  "en-JM",
  "en-MY",
  "en-NZ",
  "en-PH",
  "en-SG",
  "en-TT",
  "en-US",
  "en-ZA",
  "en-ZW",
  "es-AR",
  "es-BO",
  "es-CL",
  "es-CO",
  "es-CR",
  "es-DO",
  "es-EC",
  "es-ES",
  "es-GT",
  "es-HN",
  "es-MX",
  "es-NI",
  "es-PA",
  "es-PE",
  "es-PR",
  "es-PY",
  "es-SV",
  "es-US",
  "es-UY",
  "es-VE",
  "et-EE",
  "eu-ES",
  "fa-IR",
  "fi-FI",
  "fil-PH",
  "fo-FO",
  "fr-BE",
  "fr-CA",
  "fr-CH",
  "fr-FR",
  "fr-LU",
  "fr-MC",
  "fy-NL",
  "ga-IE",
  "gd-GB",
  "gl-ES",
  "gsw-FR",
  "gu-IN",
  "ha-Latn-NG",
  "he-IL",
  "hi-IN",
  "hr-BA",
  "hr-HR",
  "hsb-DE",
  "hu-HU",
  "hy-AM",
  "id-ID",
  "ig-NG",
  "ii-CN",
  "is-IS",
  "it-CH",
  "it-IT",
  "iu-Cans-CA",
  "iu-Latn-CA",
  "ja-JP",
  "ka-GE",
  "kk-KZ",
  "kl-GL",
  "km-KH",
  "kn-IN",
  "kok-IN",
  "ko-KR",
  "ky-KG",
  "lb-LU",
  "lo-LA",
  "lt-LT",
  "lv-LV",
  "mi-NZ",
  "mk-MK",
  "ml-IN",
  "mn-MN",
  "mn-Mong-CN",
  "moh-CA",
  "mr-IN",
  "ms-BN",
  "ms-MY",
  "mt-MT",
  "nb-NO",
  "ne-NP",
  "nl-BE",
  "nl-NL",
  "nn-NO",
  "nso-ZA",
  "oc-FR",
  "or-IN",
  "pa-IN",
  "pl-PL",
  "prs-AF",
  "ps-AF",
  "pt-BR",
  "pt-PT",
  "qut-GT",
  "quz-BO",
  "quz-EC",
  "quz-PE",
  "rm-CH",
  "ro-RO",
  "ru-RU",
  "rw-RW",
  "sah-RU",
  "sa-IN",
  "se-FI",
  "se-NO",
  "se-SE",
  "si-LK",
  "sk-SK",
  "sl-SI",
  "sma-NO",
  "sma-SE",
  "smj-NO",
  "smj-SE",
  "smn-FI",
  "sms-FI",
  "sq-AL",
  "sr-Cyrl-BA",
  "sr-Cyrl-CS",
  "sr-Cyrl-ME",
  "sr-Cyrl-RS",
  "sr-Latn-BA",
  "sr-Latn-CS",
  "sr-Latn-ME",
  "sr-Latn-RS",
  "sv-FI",
  "sv-SE",
  "sw-KE",
  "syr-SY",
  "ta-IN",
  "te-IN",
  "tg-Cyrl-TJ",
  "th-TH",
  "tk-TM",
  "tn-ZA",
  "tr-TR",
  "tt-RU",
  "tzm-Latn-DZ",
  "ug-CN",
  "uk-UA",
  "ur-PK",
  "uz-Cyrl-UZ",
  "uz-Latn-UZ",
  "vi-VN",
  "wo-SN",
  "xh-ZA",
  "yo-NG",
  "zh-CN",
  "zh-HK",
  "zh-MO",
  "zh-SG",
  "zh-TW",
  "zu-ZA",
];

export const countryLocales = [
  {
    country: "AD",
    en_name: "Andorra",
    native_name: "Andorra",
    locales: "ca",
  },
  {
    country: "AE",
    en_name: "United Arab Emirates",
    native_name: "دولة الإمارات العربيّة المتّحدة",
    locales: "ar",
  },
  {
    country: "AF",
    en_name: "Afghanistan",
    native_name:
      "د افغانستان اسلامي دولتدولت اسلامی افغانستان, جمهوری اسلامی افغانستان",
    locales: "fa, ps",
  },
  {
    country: "AG",
    en_name: "Antigua and Barbuda",
    native_name: "Antigua and Barbuda",
    locales: "en",
  },
  {
    country: "AI",
    en_name: "Anguilla",
    native_name: "Anguilla",
    locales: "en",
  },
  {
    country: "AL",
    en_name: "Albania",
    native_name: "Shqipëria",
    locales: "sq",
  },
  {
    country: "AM",
    en_name: "Armenia",
    native_name: "Հայաստան",
    locales: "hy",
  },
  {
    country: "AO",
    en_name: "Angola",
    native_name: "Angola",
    locales: "pt",
  },
  {
    country: "AQ",
    en_name: "Antarctica",
    native_name: "Antarctica, Antártico, Antarctique, Антарктике",
    locales: "en, es, fr, ru[1]",
  },
  {
    country: "AR",
    en_name: "Argentina",
    native_name: "Argentina",
    locales: "es",
  },
  {
    country: "AS",
    en_name: "American Samoa",
    native_name: "American Samoa",
    locales: "en, sm",
  },
  {
    country: "AT",
    en_name: "Austria",
    native_name: "Österreich",
    locales: "de",
  },
  {
    country: "AU",
    en_name: "Australia",
    native_name: "Australia",
    locales: "en",
  },
  {
    country: "AW",
    en_name: "Aruba",
    native_name: "Aruba",
    locales: "nl, pap",
  },
  {
    country: "AX",
    en_name: "Aland Islands",
    native_name: "Åland",
    locales: "sv",
  },
  {
    country: "AZ",
    en_name: "Azerbaijan",
    native_name: "Azərbaycan",
    locales: "az",
  },
  {
    country: "BA",
    en_name: "Bosnia and Herzegovina",
    native_name: "Bosna i Hercegovina",
    locales: "bs, hr, sr",
  },
  {
    country: "BB",
    en_name: "Barbados",
    native_name: "Barbados",
    locales: "en",
  },
  {
    country: "BD",
    en_name: "Bangladesh",
    native_name: "গণপ্রজাতন্ত্রী বাংলাদেশ",
    locales: "bn",
  },
  {
    country: "BE",
    en_name: "Belgium",
    native_name: "België, Belgique, Belgien",
    locales: "nl, fr, de",
  },
  {
    country: "BF",
    en_name: "Burkina Faso",
    native_name: "Burkina Faso",
    locales: "fr",
  },
  {
    country: "BG",
    en_name: "Bulgaria",
    native_name: "България",
    locales: "bg",
  },
  {
    country: "BH",
    en_name: "Bahrain",
    native_name: "البحرين",
    locales: "ar",
  },
  {
    country: "BI",
    en_name: "Burundi",
    native_name: "Burundi",
    locales: "fr",
  },
  {
    country: "BJ",
    en_name: "Benin",
    native_name: "Bénin",
    locales: "fr",
  },
  {
    country: "BL",
    en_name: "Saint-Barthélemy",
    native_name: "Saint-Barthélemy",
    locales: "fr",
  },
  {
    country: "BM",
    en_name: "Bermuda",
    native_name: "Bermuda",
    locales: "en",
  },
  {
    country: "BN",
    en_name: "Brunei Darussalam",
    native_name: "Brunei Darussalam",
    locales: "ms",
  },
  {
    country: "BO",
    en_name: "Bolivia",
    native_name: "Bolivia, Bulibiya, Volívia, Wuliwya",
    locales: "es, qu, gn, ay",
  },
  {
    country: "BQ",
    en_name: "Caribbean Netherlands",
    native_name: "Caribisch Nederland",
    locales: "nl",
  },
  {
    country: "BR",
    en_name: "Brazil",
    native_name: "Brasil",
    locales: "pt",
  },
  {
    country: "BS",
    en_name: "Bahamas",
    native_name: "Bahamas",
    locales: "en",
  },
  {
    country: "BT",
    en_name: "Bhutan",
    native_name: "འབྲུག་ཡུལ",
    locales: "dz",
  },
  {
    country: "BV",
    en_name: "Bouvet Island",
    native_name: "Bouvetøya",
    locales: "no",
  },
  {
    country: "BW",
    en_name: "Botswana",
    native_name: "Botswana",
    locales: "en, tn",
  },
  {
    country: "BY",
    en_name: "Belarus",
    native_name: "Беларусь",
    locales: "be, ru",
  },
  {
    country: "BZ",
    en_name: "Belize",
    native_name: "Belize",
    locales: "en",
  },
  {
    country: "CA",
    en_name: "Canada",
    native_name: "Canada",
    locales: "en, fr",
  },
  {
    country: "CC",
    en_name: "Cocos (Keeling) Islands",
    native_name: "Cocos (Keeling) Islands",
    locales: "en",
  },
  {
    country: "CD",
    en_name: "Democratic Republic of the Congo (Congo-Kinshasa, former Zaire)",
    native_name: "République Démocratique du Congo",
    locales: "fr",
  },
  {
    country: "CF",
    en_name: "Centrafrican Republic",
    native_name: "République centrafricaine, Ködörösêse tî Bêafrîka",
    locales: "fr, sg",
  },
  {
    country: "CG",
    en_name: "Republic of the Congo (Congo-Brazzaville)",
    native_name: "République du Congo",
    locales: "fr",
  },
  {
    country: "CH",
    en_name: "Switzerland",
    native_name: "Schweiz, Suisse, Svizzera, Svizra",
    locales: "de, fr, it, rm",
  },
  {
    country: "CI",
    en_name: "Côte d'Ivoire",
    native_name: "Côte d'Ivoire",
    locales: "fr",
  },
  {
    country: "CK",
    en_name: "Cook Islands",
    native_name: "Cook Islands, Kūki ʻĀirani",
    locales: "en, rar",
  },
  {
    country: "CL",
    en_name: "Chile",
    native_name: "Chile",
    locales: "es",
  },
  {
    country: "CM",
    en_name: "Cameroon",
    native_name: "Cameroun, Cameroon",
    locales: "fr, en",
  },
  {
    country: "CN",
    en_name: "China",
    native_name: "中国",
    locales: "zh-hans",
  },
  {
    country: "CO",
    en_name: "Colombia",
    native_name: "Colombia",
    locales: "es",
  },
  {
    country: "CR",
    en_name: "Costa Rica",
    native_name: "Costa Rica",
    locales: "es",
  },
  {
    country: "CU",
    en_name: "Cuba",
    native_name: "Cuba",
    locales: "es",
  },
  {
    country: "CV",
    en_name: "Cabo Verde",
    native_name: "Cabo Verde",
    locales: "pt",
  },
  {
    country: "CW",
    en_name: "Curaçao",
    native_name: "Curaçao",
    locales: "nl, en",
  },
  {
    country: "CX",
    en_name: "Christmas Island",
    native_name: "Christmas Island",
    locales: "en",
  },
  {
    country: "CY",
    en_name: "Cyprus",
    native_name: "Κύπρος, Kibris",
    locales: "el, tr",
  },
  {
    country: "CZ",
    en_name: "Czech Republic",
    native_name: "Česká republika",
    locales: "cs",
  },
  {
    country: "DE",
    en_name: "Germany",
    native_name: "Deutschland",
    locales: "de",
  },
  {
    country: "DJ",
    en_name: "Djibouti",
    native_name: "Djibouti, جيبوتي, Jabuuti, Gabuutih",
    locales: "fr, ar, so, aa",
  },
  {
    country: "DK",
    en_name: "Denmark",
    native_name: "Danmark",
    locales: "da",
  },
  {
    country: "DM",
    en_name: "Dominica",
    native_name: "Dominica",
    locales: "en",
  },
  {
    country: "DO",
    en_name: "Dominican Republic",
    native_name: "República Dominicana",
    locales: "es",
  },
  {
    country: "DZ",
    en_name: "Algeria",
    native_name: "الجزائر",
    locales: "ar",
  },
  {
    country: "EC",
    en_name: "Ecuador",
    native_name: "Ecuador",
    locales: "es",
  },
  {
    country: "EE",
    en_name: "Estonia",
    native_name: "Eesti",
    locales: "et",
  },
  {
    country: "EG",
    en_name: "Egypt",
    native_name: "مصر",
    locales: "ar",
  },
  {
    country: "EH",
    en_name: "Western Sahara",
    native_name: "Sahara Occidental",
    locales: "ar, es, fr",
  },
  {
    country: "ER",
    en_name: "Eritrea",
    native_name: "ኤርትራ, إرتريا, Eritrea",
    locales: "ti, ar, en",
  },
  {
    country: "ES",
    en_name: "Spain",
    native_name: "España",
    locales: "ast, ca, es, eu, gl",
  },
  {
    country: "ET",
    en_name: "Ethiopia",
    native_name: "ኢትዮጵያ, Itoophiyaa",
    locales: "am, om",
  },
  {
    country: "FI",
    en_name: "Finland",
    native_name: "Suomi",
    locales: "fi, sv, se",
  },
  {
    country: "FJ",
    en_name: "Fiji",
    native_name: "Fiji",
    locales: "en",
  },
  {
    country: "FK",
    en_name: "Falkland Islands",
    native_name: "Falkland Islands",
    locales: "en",
  },
  {
    country: "FM",
    en_name: "Micronesia (Federated States of)",
    native_name: "Micronesia",
    locales: "en",
  },
  {
    country: "FO",
    en_name: "Faroe Islands",
    native_name: "Føroyar, Færøerne",
    locales: "fo, da",
  },
  {
    country: "FR",
    en_name: "France",
    native_name: "France",
    locales: "fr",
  },
  {
    country: "GA",
    en_name: "Gabon",
    native_name: "Gabon",
    locales: "fr",
  },
  {
    country: "GB",
    en_name: "United Kingdom",
    native_name: "United Kingdom",
    locales: "en, ga, cy, gd, kw",
  },
  {
    country: "GD",
    en_name: "Grenada",
    native_name: "Grenada",
    locales: "en",
  },
  {
    country: "GE",
    en_name: "Georgia",
    native_name: "საქართველო",
    locales: "ka",
  },
  {
    country: "GF",
    en_name: "French Guiana",
    native_name: "Guyane française",
    locales: "fr",
  },
  {
    country: "GG",
    en_name: "Guernsey",
    native_name: "Guernsey",
    locales: "en",
  },
  {
    country: "GH",
    en_name: "Ghana",
    native_name: "Ghana",
    locales: "en",
  },
  {
    country: "GI",
    en_name: "Gibraltar",
    native_name: "Gibraltar",
    locales: "en",
  },
  {
    country: "GL",
    en_name: "Greenland",
    native_name: "Kalaallit Nunaat, Grønland",
    locales: "kl, da",
  },
  {
    country: "GM",
    en_name: "The Gambia",
    native_name: "The Gambia",
    locales: "en",
  },
  {
    country: "GN",
    en_name: "Guinea",
    native_name: "Guinée",
    locales: "fr",
  },
  {
    country: "GP",
    en_name: "Guadeloupe",
    native_name: "Guadeloupe",
    locales: "fr",
  },
  {
    country: "GQ",
    en_name: "Equatorial Guinea",
    native_name: "Guiena ecuatorial, Guinée équatoriale, Guiné Equatorial",
    locales: "es, fr, pt",
  },
  {
    country: "GR",
    en_name: "Greece",
    native_name: "Ελλάδα",
    locales: "el",
  },
  {
    country: "GS",
    en_name: "South Georgia and the South Sandwich Islands",
    native_name: "South Georgia and the South Sandwich Islands",
    locales: "en",
  },
  {
    country: "GT",
    en_name: "Guatemala",
    native_name: "Guatemala",
    locales: "es",
  },
  {
    country: "GU",
    en_name: "Guam",
    native_name: "Guam, Guåhån",
    locales: "en, ch",
  },
  {
    country: "GW",
    en_name: "Guinea Bissau",
    native_name: "Guiné-Bissau",
    locales: "pt",
  },
  {
    country: "GY",
    en_name: "Guyana",
    native_name: "Guyana",
    locales: "en",
  },
  {
    country: "HK",
    en_name: "Hong Kong (SAR of China)",
    native_name: "香港, Hong Kong",
    locales: "zh-hant, en",
  },
  {
    country: "HM",
    en_name: "Heard Island and McDonald Islands",
    native_name: "Heard Island and McDonald Islands",
    locales: "en",
  },
  {
    country: "HN",
    en_name: "Honduras",
    native_name: "Honduras",
    locales: "es",
  },
  {
    country: "HR",
    en_name: "Croatia",
    native_name: "Hrvatska",
    locales: "hr",
  },
  {
    country: "HT",
    en_name: "Haiti",
    native_name: "Haïti, Ayiti",
    locales: "fr, ht",
  },
  {
    country: "HU",
    en_name: "Hungary",
    native_name: "Magyarország",
    locales: "hu",
  },
  {
    country: "ID",
    en_name: "Indonesia",
    native_name: "Indonesia",
    locales: "id",
  },
  {
    country: "IE",
    en_name: "Ireland",
    native_name: "Ireland, Éire",
    locales: "en, ga",
  },
  {
    country: "IL",
    en_name: "Israel",
    native_name: "ישראל",
    locales: "he",
  },
  {
    country: "IM",
    en_name: "Isle of Man",
    native_name: "Isle of Man",
    locales: "en",
  },
  {
    country: "IN",
    en_name: "India",
    native_name: "भारत, India",
    locales: "hi, en",
  },
  {
    country: "IO",
    en_name: "British Indian Ocean Territory",
    native_name: "British Indian Ocean Territory",
    locales: "en",
  },
  {
    country: "IQ",
    en_name: "Iraq",
    native_name: "العراق, Iraq",
    locales: "ar, ku",
  },
  {
    country: "IR",
    en_name: "Iran",
    native_name: "ایران",
    locales: "fa",
  },
  {
    country: "IS",
    en_name: "Iceland",
    native_name: "Ísland",
    locales: "is",
  },
  {
    country: "IT",
    en_name: "Italy",
    native_name: "Italia",
    locales: "it, de, fr",
  },
  {
    country: "JE",
    en_name: "Jersey",
    native_name: "Jersey",
    locales: "en",
  },
  {
    country: "JM",
    en_name: "Jamaica",
    native_name: "Jamaica",
    locales: "en",
  },
  {
    country: "JO",
    en_name: "Jordan",
    native_name: "الأُرْدُن",
    locales: "ar",
  },
  {
    country: "JP",
    en_name: "Japan",
    native_name: "日本",
    locales: "ja",
  },
  {
    country: "KE",
    en_name: "Kenya",
    native_name: "Kenya",
    locales: "sw, en",
  },
  {
    country: "KG",
    en_name: "Kyrgyzstan",
    native_name: "Кыргызстан, Киргизия",
    locales: "ky, ru",
  },
  {
    country: "KH",
    en_name: "Cambodia",
    native_name: "កម្ពុជា",
    locales: "km",
  },
  {
    country: "KI",
    en_name: "Kiribati",
    native_name: "Kiribati",
    locales: "en",
  },
  {
    country: "KM",
    en_name: "Comores",
    native_name: "ﺍﻟﻘﻤﺮي, Comores, Komori",
    locales: "ar, fr, sw",
  },
  {
    country: "KN",
    en_name: "Saint Kitts and Nevis",
    native_name: "Saint Kitts and Nevis",
    locales: "en",
  },
  {
    country: "KP",
    en_name: "North Korea",
    native_name: "북조선",
    locales: "ko",
  },
  {
    country: "KR",
    en_name: "South Korea",
    native_name: "대한민국",
    locales: "ko, en",
  },
  {
    country: "KW",
    en_name: "Kuwait",
    native_name: "الكويت",
    locales: "ar",
  },
  {
    country: "KY",
    en_name: "Cayman Islands",
    native_name: "Cayman Islands",
    locales: "en",
  },
  {
    country: "KZ",
    en_name: "Kazakhstan",
    native_name: "Қазақстан, Казахстан",
    locales: "kk, ru",
  },
  {
    country: "LA",
    en_name: "Laos",
    native_name: "ປະຊາຊົນລາວ",
    locales: "lo",
  },
  {
    country: "LB",
    en_name: "Lebanon",
    native_name: "لبنان, Liban",
    locales: "ar, fr",
  },
  {
    country: "LC",
    en_name: "Saint Lucia",
    native_name: "Saint Lucia",
    locales: "en",
  },
  {
    country: "LI",
    en_name: "Liechtenstein",
    native_name: "Liechtenstein",
    locales: "de",
  },
  {
    country: "LK",
    en_name: "Sri Lanka",
    native_name: "ශ්‍රී ලංකා, இலங்கை",
    locales: "si, ta",
  },
  {
    country: "LR",
    en_name: "Liberia",
    native_name: "Liberia",
    locales: "en",
  },
  {
    country: "LS",
    en_name: "Lesotho",
    native_name: "Lesotho",
    locales: "en, st",
  },
  {
    country: "LT",
    en_name: "Lithuania",
    native_name: "Lietuva",
    locales: "lt",
  },
  {
    country: "LU",
    en_name: "Luxembourg",
    native_name: "Lëtzebuerg, Luxembourg, Luxemburg",
    locales: "lb, fr, de",
  },
  {
    country: "LV",
    en_name: "Latvia",
    native_name: "Latvija",
    locales: "lv",
  },
  {
    country: "LY",
    en_name: "Libya",
    native_name: "ليبيا",
    locales: "ar",
  },
  {
    country: "MA",
    en_name: "Morocco",
    native_name: "Maroc, ⵍⵎⵖⵔⵉⴱ, المغرب",
    locales: "fr, zgh, ar",
  },
  {
    country: "MC",
    en_name: "Monaco",
    native_name: "Monaco",
    locales: "fr",
  },
  {
    country: "MD",
    en_name: "Moldova",
    native_name: "Moldova, Молдавия",
    locales: "ro, ru, uk",
  },
  {
    country: "ME",
    en_name: "Montenegro",
    native_name: "Crna Gora, Црна Гора",
    locales: "srp, sr, hr, bs, sq",
  },
  {
    country: "MF",
    en_name: "Saint Martin (French part)",
    native_name: "Saint-Martin",
    locales: "fr",
  },
  {
    country: "MG",
    en_name: "Madagascar",
    native_name: "Madagasikara, Madagascar",
    locales: "mg, fr",
  },
  {
    country: "MH",
    en_name: "Marshall Islands",
    native_name: "Marshall Islands",
    locales: "en, mh",
  },
  {
    country: "MK",
    en_name: "Macedonia (Former Yugoslav Republic of)",
    native_name: "Македонија",
    locales: "mk",
  },
  {
    country: "ML",
    en_name: "Mali",
    native_name: "Mali",
    locales: "fr",
  },
  {
    country: "MM",
    en_name: "Myanmar",
    native_name: "မြန်မာ",
    locales: "my",
  },
  {
    country: "MN",
    en_name: "Mongolia",
    native_name: "Монгол Улс",
    locales: "mn",
  },
  {
    country: "MO",
    en_name: "Macao (SAR of China)",
    native_name: "澳門, Macau",
    locales: "zh-hant, pt",
  },
  {
    country: "MP",
    en_name: "Northern Mariana Islands",
    native_name: "Northern Mariana Islands",
    locales: "en, ch",
  },
  {
    country: "MQ",
    en_name: "Martinique",
    native_name: "Martinique",
    locales: "fr",
  },
  {
    country: "MR",
    en_name: "Mauritania",
    native_name: "موريتانيا, Mauritanie",
    locales: "ar, fr",
  },
  {
    country: "MS",
    en_name: "Montserrat",
    native_name: "Montserrat",
    locales: "en",
  },
  {
    country: "MT",
    en_name: "Malta",
    native_name: "Malta",
    locales: "mt, en",
  },
  {
    country: "MU",
    en_name: "Mauritius",
    native_name: "Maurice, Mauritius",
    locales: "mfe, fr, en",
  },
  {
    country: "MV",
    en_name: "Maldives",
    native_name: "",
    locales: "dv",
  },
  {
    country: "MW",
    en_name: "Malawi",
    native_name: "Malawi",
    locales: "en, ny",
  },
  {
    country: "MX",
    en_name: "Mexico",
    native_name: "México",
    locales: "es",
  },
  {
    country: "MY",
    en_name: "Malaysia",
    native_name: "",
    locales: "ms",
  },
  {
    country: "MZ",
    en_name: "Mozambique",
    native_name: "Mozambique",
    locales: "pt",
  },
  {
    country: "NA",
    en_name: "Namibia",
    native_name: "Namibia",
    locales: "en, sf, de",
  },
  {
    country: "NC",
    en_name: "New Caledonia",
    native_name: "Nouvelle-Calédonie",
    locales: "fr",
  },
  {
    country: "NE",
    en_name: "Niger",
    native_name: "Niger",
    locales: "fr",
  },
  {
    country: "NF",
    en_name: "Norfolk Island",
    native_name: "Norfolk Island",
    locales: "en, pih",
  },
  {
    country: "NG",
    en_name: "Nigeria",
    native_name: "Nigeria",
    locales: "en",
  },
  {
    country: "NI",
    en_name: "Nicaragua",
    native_name: "Nicaragua",
    locales: "es",
  },
  {
    country: "NL",
    en_name: "The Netherlands",
    native_name: "Nederland",
    locales: "nl",
  },
  {
    country: "NO",
    en_name: "Norway",
    native_name: "Norge, Noreg",
    locales: "nb, nn, no, se",
  },
  {
    country: "NP",
    en_name: "Nepal",
    native_name: "",
    locales: "ne",
  },
  {
    country: "NR",
    en_name: "Nauru",
    native_name: "Nauru",
    locales: "na, en",
  },
  {
    country: "NU",
    en_name: "Niue",
    native_name: "Niue",
    locales: "niu, en",
  },
  {
    country: "NZ",
    en_name: "New Zealand",
    native_name: "New Zealand",
    locales: "mi, en",
  },
  {
    country: "OM",
    en_name: "Oman",
    native_name: "سلطنة عُمان",
    locales: "ar",
  },
  {
    country: "PA",
    en_name: "Panama",
    native_name: "Panama",
    locales: "es",
  },
  {
    country: "PE",
    en_name: "Peru",
    native_name: "Perú",
    locales: "es",
  },
  {
    country: "PF",
    en_name: "French Polynesia",
    native_name: "Polynésie française",
    locales: "fr",
  },
  {
    country: "PG",
    en_name: "Papua New Guinea",
    native_name: "Papua New Guinea",
    locales: "en, tpi, ho",
  },
  {
    country: "PH",
    en_name: "Philippines",
    native_name: "Philippines",
    locales: "en, tl",
  },
  {
    country: "PK",
    en_name: "Pakistan",
    native_name: "پاکستان",
    locales: "en, ur",
  },
  {
    country: "PL",
    en_name: "Poland",
    native_name: "Polska",
    locales: "pl",
  },
  {
    country: "PM",
    en_name: "Saint Pierre and Miquelon",
    native_name: "Saint-Pierre-et-Miquelon",
    locales: "fr",
  },
  {
    country: "PN",
    en_name: "Pitcairn",
    native_name: "Pitcairn",
    locales: "en, pih",
  },
  {
    country: "PR",
    en_name: "Puerto Rico",
    native_name: "Puerto Rico",
    locales: "es, en",
  },
  {
    country: "PS",
    en_name: "Palestinian Territory",
    native_name: "Palestinian Territory",
    locales: "ar, he",
  },
  {
    country: "PT",
    en_name: "Portugal",
    native_name: "Portugal",
    locales: "pt",
  },
  {
    country: "PW",
    en_name: "Palau",
    native_name: "Palau",
    locales: "en, pau, ja, sov, tox",
  },
  {
    country: "PY",
    en_name: "Paraguay",
    native_name: "Paraguay",
    locales: "es, gn",
  },
  {
    country: "QA",
    en_name: "Qatar",
    native_name: "قطر",
    locales: "ar",
  },
  {
    country: "RE",
    en_name: "Reunion",
    native_name: "La Réunion",
    locales: "fr",
  },
  {
    country: "RO",
    en_name: "Romania",
    native_name: "România",
    locales: "ro",
  },
  {
    country: "RS",
    en_name: "Serbia",
    native_name: "Србија",
    locales: "sr, sr-Latn",
  },
  {
    country: "RU",
    en_name: "Russia",
    native_name: "Россия",
    locales: "ru",
  },
  {
    country: "RW",
    en_name: "Rwanda",
    native_name: "Rwanda",
    locales: "rw, fr, en",
  },
  {
    country: "SA",
    en_name: "Saudi Arabia",
    native_name: "السعودية",
    locales: "ar",
  },
  {
    country: "SB",
    en_name: "Solomon Islands",
    native_name: "Solomon Islands",
    locales: "en",
  },
  {
    country: "SC",
    en_name: "Seychelles",
    native_name: "Seychelles",
    locales: "fr, en, crs",
  },
  {
    country: "SD",
    en_name: "Sudan",
    native_name: "السودان",
    locales: "ar, en",
  },
  {
    country: "SE",
    en_name: "Sweden",
    native_name: "Sverige",
    locales: "sv",
  },
  {
    country: "SG",
    en_name: "Singapore",
    native_name: "Singapore",
    locales: "zh-hans, en, ms, ta",
  },
  {
    country: "SH",
    en_name: "Saint Helena",
    native_name: "Saint Helena",
    locales: "en",
  },
  {
    country: "SI",
    en_name: "Slovenia",
    native_name: "Slovenija",
    locales: "sl",
  },
  {
    country: "SJ",
    en_name: "Svalbard and Jan Mayen",
    native_name: "Svalbard and Jan Mayen",
    locales: "no",
  },
  {
    country: "SK",
    en_name: "Slovakia",
    native_name: "Slovensko",
    locales: "sk",
  },
  {
    country: "SL",
    en_name: "Sierra Leone",
    native_name: "Sierra Leone",
    locales: "en",
  },
  {
    country: "SM",
    en_name: "San Marino",
    native_name: "San Marino",
    locales: "it",
  },
  {
    country: "SN",
    en_name: "Sénégal",
    native_name: "Sénégal",
    locales: "fr",
  },
  {
    country: "SO",
    en_name: "Somalia",
    native_name: "Somalia, الصومال",
    locales: "so, ar",
  },
  {
    country: "SR",
    en_name: "Suriname",
    native_name: "Suriname",
    locales: "nl",
  },
  {
    country: "ST",
    en_name: "São Tomé and Príncipe",
    native_name: "São Tomé e Príncipe",
    locales: "pt",
  },
  {
    country: "SS",
    en_name: "South Sudan",
    native_name: "South Sudan",
    locales: "en",
  },
  {
    country: "SV",
    en_name: "El Salvador",
    native_name: "El Salvador",
    locales: "es",
  },
  {
    country: "SX",
    en_name: "Saint Martin (Dutch part)",
    native_name: "Sint Maarten",
    locales: "nl, en",
  },
  {
    country: "SY",
    en_name: "Syria",
    native_name: "سوريا, Sūriyya",
    locales: "ar",
  },
  {
    country: "SZ",
    en_name: "Swaziland",
    native_name: "Swaziland",
    locales: "en, ss",
  },
  {
    country: "TC",
    en_name: "Turks and Caicos Islands",
    native_name: "Turks and Caicos Islands",
    locales: "en",
  },
  {
    country: "TD",
    en_name: "Chad",
    native_name: "Tchad, تشاد",
    locales: "fr, ar",
  },
  {
    country: "TF",
    en_name: "French Southern and Antarctic Lands",
    native_name: "Terres australes et antarctiques françaises",
    locales: "fr",
  },
  {
    country: "TG",
    en_name: "Togo",
    native_name: "Togo",
    locales: "fr",
  },
  {
    country: "TH",
    en_name: "Thailand",
    native_name: "ประเทศไทย",
    locales: "th",
  },
  {
    country: "TJ",
    en_name: "Tajikistan",
    native_name: ",",
    locales: "tg, ru",
  },
  {
    country: "TK",
    en_name: "Tokelau",
    native_name: "Tokelau",
    locales: "tkl, en, sm",
  },
  {
    country: "TL",
    en_name: "Timor-Leste",
    native_name: "Timor-Leste, Timor Lorosa'e",
    locales: "pt, tet",
  },
  {
    country: "TM",
    en_name: "Turkmenistan",
    native_name: "Türkmenistan",
    locales: "tk",
  },
  {
    country: "TN",
    en_name: "Tunisia",
    native_name: "تونس, Tunisie",
    locales: "ar, fr",
  },
  {
    country: "TO",
    en_name: "Tonga",
    native_name: "Tonga",
    locales: "en",
  },
  {
    country: "TR",
    en_name: "Turkey",
    native_name: "Türkiye",
    locales: "tr",
  },
  {
    country: "TT",
    en_name: "Trinidad and Tobago",
    native_name: "Trinidad and Tobago",
    locales: "en",
  },
  {
    country: "TV",
    en_name: "Tuvalu",
    native_name: "Tuvalu",
    locales: "en",
  },
  {
    country: "TW",
    en_name: "Taiwan",
    native_name: "Taiwan",
    locales: "zh-hant",
  },
  {
    country: "TZ",
    en_name: "Tanzania",
    native_name: "Tanzania",
    locales: "sw, en",
  },
  {
    country: "UA",
    en_name: "Ukraine",
    native_name: "Україна",
    locales: "uk",
  },
  {
    country: "UG",
    en_name: "Uganda",
    native_name: "Uganda",
    locales: "en, sw",
  },
  {
    country: "UM",
    en_name: "United States Minor Outlying Islands",
    native_name: "United States Minor Outlying Islands",
    locales: "en",
  },
  {
    country: "US",
    en_name: "United States of America",
    native_name: "United States of America",
    locales: "en",
  },
  {
    country: "UY",
    en_name: "Uruguay",
    native_name: "Uruguay",
    locales: "es",
  },
  {
    country: "UZ",
    en_name: "Uzbekistan",
    native_name: "",
    locales: "uz, kaa",
  },
  {
    country: "VA",
    en_name: "City of the Vatican",
    native_name: "Città del Vaticano",
    locales: "it",
  },
  {
    country: "VC",
    en_name: "Saint Vincent and the Grenadines",
    native_name: "Saint Vincent and the Grenadines",
    locales: "en",
  },
  {
    country: "VE",
    en_name: "Venezuela",
    native_name: "Venezuela",
    locales: "es",
  },
  {
    country: "VG",
    en_name: "British Virgin Islands",
    native_name: "British Virgin Islands",
    locales: "en",
  },
  {
    country: "VI",
    en_name: "United States Virgin Islands",
    native_name: "United States Virgin Islands",
    locales: "en",
  },
  {
    country: "VN",
    en_name: "Vietnam",
    native_name: "Việt Nam",
    locales: "vi",
  },
  {
    country: "VU",
    en_name: "Vanuatu",
    native_name: "Vanuatu",
    locales: "bi, en, fr",
  },
  {
    country: "WF",
    en_name: "Wallis and Futuna",
    native_name: "Wallis-et-Futuna",
    locales: "fr",
  },
  {
    country: "WS",
    en_name: "Samoa",
    native_name: "Samoa",
    locales: "sm, en",
  },
  {
    country: "YE",
    en_name: "Yemen",
    native_name: "اليَمَن",
    locales: "ar",
  },
  {
    country: "YT",
    en_name: "Mayotte",
    native_name: "Mayotte",
    locales: "fr",
  },
  {
    country: "ZA",
    en_name: "South Africa",
    native_name: "South Africa",
    locales: "en, af, st, tn, xh, zu",
  },
  {
    country: "ZM",
    en_name: "Zambia",
    native_name: "Zambia",
    locales: "en",
  },
  {
    country: "ZW",
    en_name: "Zimbabwe",
    native_name: "Zimbabwe",
    locales: "en, sn, nd",
  },
];
