import { IMaskInput, IMaskInputProps } from "react-imask";

export default function PhoneInput({
  className = "",
  ...others
}: IMaskInputProps<HTMLInputElement>) {
  return (
    <div
      className={`transition border border-gray-300 focus:border-black rounded-xl py-3 px-3 ${className}`}
    >
      <IMaskInput
        className="w-full bg-transparent focus:outline-none text-gray-900 placeholder:text-gray-400"
        {...others}
      />
    </div>
  );
}
