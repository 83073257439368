// import React, { useState } from "react";
// import { Grid } from 'lucide-react';
// import { Button } from "./ui/button";
// import { Dialog, DialogContent } from "./ui/dialog";

// const images = [
//   {
//     src: "https://plus.unsplash.com/premium_photo-1661964071015-d97428970584?fm=jpg&q=60&w=3000&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MXx8aG90ZWx8ZW58MHx8MHx8fDA%3D",
//     alt: "Living room with modern furniture",
//     className: "w-[800px] h-[800px] object-cover w-full h-full cursor-pointer"
//   },
//   {
//     src: "https://static.tacdn.com/img2/branding/homepage/hero-hotel-800x520.jpg",
//     alt: "Kitchen view",
//     className: "w-[400px] h-[300px] object-cover w-full h-full cursor-pointer"
//   },
//   {
//     src: "https://hoteldel.com/wp-content/uploads/2021/01/hotel-del-coronado-views-suite-K1TOS1-K1TOJ1-1600x900-1.jpg",
//     alt: "Bedroom with large windows",
//     className: "w-[400px] h-[300px] object-cover w-full h-full cursor-pointer"
//   },
//   {
//     src: "https://images.trvl-media.com/lodging/1000000/10000/3400/3313/0bb36e4a.jpg?impolicy=fcrop&w=1200&h=800&p=1&q=medium",
//     alt: "Modern bathroom",
//     className: "w-[400px] h-[300px] object-cover w-full h-full cursor-pointer"
//   },
//   {
//     src: "https://plus.unsplash.com/premium_photo-1661876403473-64980cbfdf0f?fm=jpg&q=60&w=3000&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OXx8aG90ZWwlMjBkZXNrfGVufDB8fDB8fHww",
//     alt: "Study corner",
//     className: "w-[400px] h-[300px] object-cover w-full h-full cursor-pointer"
//   },
//   // Additional 10 images
//   {
//     src: "https://images.unsplash.com/photo-1566073771259-6a8506099945?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Nnx8aG90ZWx8ZW58MHx8MHx8fDA%3D&auto=format&fit=crop&w=800&q=60",
//     alt: "Hotel lobby",
//     className: "w-[400px] h-[300px] object-cover w-full h-full cursor-pointer"
//   },
//   {
//     src: "https://images.unsplash.com/photo-1582719478250-c89cae4dc85b?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8aG90ZWwlMjByb29tfGVufDB8fDB8fHww&auto=format&fit=crop&w=800&q=60",
//     alt: "Luxurious hotel room",
//     className: "w-[400px] h-[300px] object-cover w-full h-full cursor-pointer"
//   },
//   {
//     src: "https://images.unsplash.com/photo-1584132967334-10e028bd69f7?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NXx8aG90ZWwlMjBwb29sfGVufDB8fDB8fHww&auto=format&fit=crop&w=800&q=60",
//     alt: "Hotel swimming pool",
//     className: "w-[400px] h-[300px] object-cover w-full h-full cursor-pointer"
//   },
//   {
//     src: "https://images.unsplash.com/photo-1551882547-ff40c63fe5fa?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8aG90ZWwlMjByZXN0YXVyYW50fGVufDB8fDB8fHww&auto=format&fit=crop&w=800&q=60",
//     alt: "Hotel restaurant",
//     className: "w-[400px] h-[300px] object-cover w-full h-full cursor-pointer"
//   },
//   {
//     src: "https://images.unsplash.com/photo-1574643156929-51fa098b0394?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Nnx8aG90ZWwlMjBiYXJ8ZW5ufDB8fDB8fHww&auto=format&fit=crop&w=800&q=60",
//     alt: "Hotel bar",
//     className: "w-[400px] h-[300px] object-cover w-full h-full cursor-pointer"
//   },
//   {
//     src: "https://images.unsplash.com/photo-1580977276076-ae4b8c219b8e?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8aG90ZWwlMjBzcGF8ZW58MHx8MHx8fDA%3D&auto=format&fit=crop&w=800&q=60",
//     alt: "Hotel spa",
//     className: "w-[400px] h-[300px] object-cover w-full h-full cursor-pointer"
//   },
//   {
//     src: "https://images.unsplash.com/photo-1604709177225-055f99402ea3?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8aG90ZWwlMjBneW18ZW58MHx8MHx8fDA%3D&auto=format&fit=crop&w=800&q=60",
//     alt: "Hotel gym",
//     className: "w-[400px] h-[300px] object-cover w-full h-full cursor-pointer"
//   },
//   {
//     src: "https://images.unsplash.com/photo-1520250497591-112f2f40a3f4?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8aG90ZWwlMjBjb25mZXJlbmNlJTIwcm9vbXxlbnwwfHwwfHx8MA%3D%3D&auto=format&fit=crop&w=800&q=60",
//     alt: "Hotel conference room",
//     className: "w-[400px] h-[300px] object-cover w-full h-full cursor-pointer"
//   },
//   {
//     src: "https://images.unsplash.com/photo-1584132915807-fd1f5fbc078f?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8aG90ZWwlMjBiYWxjb255fGVufDB8fDB8fHww&auto=format&fit=crop&w=800&q=60",
//     alt: "Hotel room balcony",
//     className: "w-[400px] h-[300px] object-cover w-full h-full cursor-pointer"
//   },
//   {
//     src: "https://images.unsplash.com/photo-1551632436-cbf8dd35adfa?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8aG90ZWwlMjBicmVha2Zhc3R8ZW58MHx8MHx8fDA%3D&auto=format&fit=crop&w=800&q=60",
//     alt: "Hotel breakfast",
//     className: "w-[400px] h-[300px] object-cover w-full h-full cursor-pointer"
//   }
// ];

// export function ImageGallery() {
//   const [showAllPhotos, setShowAllPhotos] = useState(false);

//   return (
//     <>
//       <div className="relative mt-20">
//         <div className="mt-5 mb-5">
//           <p className="font-semibold text-[25px]">Amstel Nest - an urban retreat for two</p>
//         </div>
//         <div className="grid grid-cols-4 gap-2 rounded-xl overflow-hidden md:aspect-[16/6.5] aspect-[16/9]">
//           <div className="col-span-2 row-span-2 relative">
//             <img
//               src={images[0].src}
//               alt={images[0].alt}
//               onClick={() => setShowAllPhotos(true)}
//               className={images[0].className}
//             />
//           </div>
//           {images.slice(1, 5).map((image, i) => (
//             <div key={i} className="relative">
//               <img
//                 src={image.src}
//                 alt={image.alt}
//                 onClick={() => setShowAllPhotos(true)}
//                 className="object-cover w-full h-full cursor-pointer"
//               />
//             </div>
//           ))}
//         </div>

//         <div className="absolute bottom-4 right-4 flex gap-2">
//           <Button 
//             variant="secondary" 
//             className="bg-white hover:bg-white/90"
//             onClick={() => setShowAllPhotos(true)}
//           >
//             <Grid className="mr-2 h-4 w-4" />
//             Show all photos
//           </Button>
//         </div>
//       </div> 

//       <Dialog open={showAllPhotos} onOpenChange={setShowAllPhotos}>
//         <DialogContent className="max-w-7xl w-full h-[100vh] p-4">
//           <div className="h-full overflow-y-auto">
//             <div className="columns-1 md:columns-2 gap-4 space-y-4">
//               {images.map((image, i) => (
//                 <div 
//                   key={i} 
//                   className={`break-inside-avoid ${
//                     i === 0 || i === 4 || i === 8 ? 'h-[500px]' : 'h-[300px]'
//                   }`}
//                 >
//                   <img
//                     src={image.src}
//                     alt={image.alt}
//                     className="w-full h-full object-cover rounded-lg"
//                   />
//                 </div>
//               ))}
//             </div>
//           </div>
//         </DialogContent>
//       </Dialog>
//     </>
//   );
// }

'use client'

import React, { useState } from 'react'
import { Dialog, DialogContent } from './ui/dialog'
import { Grid } from 'lucide-react'
import { ImageSlider } from './ImageSlider'
import { Button } from './ui/button'

const images = [
  {
    src: "https://plus.unsplash.com/premium_photo-1661964071015-d97428970584?fm=jpg&q=60&w=3000&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MXx8aG90ZWx8ZW58MHx8MHx8fDA%3D",
    alt: "Living room with modern furniture",
    className: "w-[800px] h-[800px] object-cover w-full h-full cursor-pointer"
  },
  {
    src: "https://static.tacdn.com/img2/branding/homepage/hero-hotel-800x520.jpg",
    alt: "Kitchen view",
    className: "w-[400px] h-[300px] object-cover w-full h-full cursor-pointer"
  },
  {
    src: "https://hoteldel.com/wp-content/uploads/2021/01/hotel-del-coronado-views-suite-K1TOS1-K1TOJ1-1600x900-1.jpg",
    alt: "Bedroom with large windows",
    className: "w-[400px] h-[300px] object-cover w-full h-full cursor-pointer"
  },
  {
    src: "https://images.trvl-media.com/lodging/1000000/10000/3400/3313/0bb36e4a.jpg?impolicy=fcrop&w=1200&h=800&p=1&q=medium",
    alt: "Modern bathroom",
    className: "w-[400px] h-[300px] object-cover w-full h-full cursor-pointer"
  },
  {
    src: "https://plus.unsplash.com/premium_photo-1661876403473-64980cbfdf0f?fm=jpg&q=60&w=3000&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OXx8aG90ZWwlMjBkZXNrfGVufDB8fDB8fHww",
    alt: "Study corner",
    className: "w-[400px] h-[300px] object-cover w-full h-full cursor-pointer"
  },
  {
    src: "https://images.unsplash.com/photo-1566073771259-6a8506099945?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Nnx8aG90ZWx8ZW58MHx8MHx8fDA%3D&auto=format&fit=crop&w=800&q=60",
    alt: "Hotel lobby",
    className: "w-[400px] h-[300px] object-cover w-full h-full cursor-pointer"
  },
  {
    src: "https://images.unsplash.com/photo-1582719478250-c89cae4dc85b?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8aG90ZWwlMjByb29tfGVufDB8fDB8fHww&auto=format&fit=crop&w=800&q=60",
    alt: "Luxurious hotel room",
    className: "w-[400px] h-[300px] object-cover w-full h-full cursor-pointer"
  },
  {
    src: "https://images.unsplash.com/photo-1584132967334-10e028bd69f7?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NXx8aG90ZWwlMjBwb29sfGVufDB8fDB8fHww&auto=format&fit=crop&w=800&q=60",
    alt: "Hotel swimming pool",
    className: "w-[400px] h-[300px] object-cover w-full h-full cursor-pointer"
  },
  {
    src: "https://images.unsplash.com/photo-1551882547-ff40c63fe5fa?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8aG90ZWwlMjByZXN0YXVyYW50fGVufDB8fDB8fHww&auto=format&fit=crop&w=800&q=60",
    alt: "Hotel restaurant",
    className: "w-[400px] h-[300px] object-cover w-full h-full cursor-pointer"
  },
  {
    src: "https://images.unsplash.com/photo-1574643156929-51fa098b0394?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Nnx8aG90ZWwlMjBiYXJ8ZW5ufDB8fDB8fHww&auto=format&fit=crop&w=800&q=60",
    alt: "Hotel bar",
    className: "w-[400px] h-[300px] object-cover w-full h-full cursor-pointer"
  },
  {
    src: "https://images.unsplash.com/photo-1580977276076-ae4b8c219b8e?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8aG90ZWwlMjBzcGF8ZW58MHx8MHx8fDA%3D&auto=format&fit=crop&w=800&q=60",
    alt: "Hotel spa",
    className: "w-[400px] h-[300px] object-cover w-full h-full cursor-pointer"
  },
  {
    src: "https://images.unsplash.com/photo-1604709177225-055f99402ea3?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8aG90ZWwlMjBneW18ZW58MHx8MHx8fDA%3D&auto=format&fit=crop&w=800&q=60",
    alt: "Hotel gym",
    className: "w-[400px] h-[300px] object-cover w-full h-full cursor-pointer"
  },
  {
    src: "https://images.unsplash.com/photo-1520250497591-112f2f40a3f4?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8aG90ZWwlMjBjb25mZXJlbmNlJTIwcm9vbXxlbnwwfHwwfHx8MA%3D%3D&auto=format&fit=crop&w=800&q=60",
    alt: "Hotel conference room",
    className: "w-[400px] h-[300px] object-cover w-full h-full cursor-pointer"
  },
  {
    src: "https://images.unsplash.com/photo-1584132915807-fd1f5fbc078f?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8aG90ZWwlMjBiYWxjb255fGVufDB8fDB8fHww&auto=format&fit=crop&w=800&q=60",
    alt: "Hotel room balcony",
    className: "w-[400px] h-[300px] object-cover w-full h-full cursor-pointer"
  },
  {
    src: "https://images.unsplash.com/photo-1551632436-cbf8dd35adfa?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8aG90ZWwlMjBicmVha2Zhc3R8ZW58MHx8MHx8fDA%3D&auto=format&fit=crop&w=800&q=60",
    alt: "Hotel breakfast",
    className: "w-[400px] h-[300px] object-cover w-full h-full cursor-pointer"
  }
]

export function ImageGallery() {
  const [showAllPhotos, setShowAllPhotos] = useState(false);

  return (
    <>
      <div className="relative mt-20">
        <div className="mt-5 mb-5">
          <p className="font-semibold text-[25px]">Amstel Nest - an urban retreat for two</p>
        </div>
        {/* Mobile Slider */}
        <div className="block md:hidden">
          <ImageSlider 
            images={images} 
            onShowAllPhotos={() => setShowAllPhotos(true)} 
          />
        </div>
        {/* Desktop Grid */}
        <div className="hidden md:block">
          <div className="grid grid-cols-4 gap-2 rounded-xl overflow-hidden md:aspect-[16/6.5] aspect-[16/9]">
            <div className="col-span-2 row-span-2 relative">
              <img
                src={images[0].src}
                alt={images[0].alt}
                onClick={() => setShowAllPhotos(true)}
                className={images[0].className}
              />
            </div>
            {images.slice(1, 5).map((image, i) => (
              <div key={i} className="relative">
                <img
                  src={image.src}
                  alt={image.alt}
                  onClick={() => setShowAllPhotos(true)}
                  className="object-cover w-full h-full cursor-pointer"
                />
              </div>
            ))}
          </div>
          <div className="absolute bottom-4 right-4 flex gap-2">
            <Button 
              variant="secondary" 
              className="bg-white hover:bg-white/90"
              onClick={() => setShowAllPhotos(true)}
            >
              <Grid className="mr-2 h-4 w-4" />
              Show all photos
            </Button>
          </div>
        </div>
      </div> 

      <Dialog open={showAllPhotos} onOpenChange={setShowAllPhotos}>
        <DialogContent className="max-w-7xl w-full h-[100vh] p-4">
          <div className="h-full overflow-y-auto">
            <div className="columns-1 md:columns-2 gap-4 space-y-4">
              {images.map((image, i) => (
                <div 
                  key={i} 
                  className={`break-inside-avoid ${
                    i === 0 || i === 4 || i === 8 ? 'h-[500px]' : 'h-[300px]'
                  }`}
                >
                  <img
                    src={image.src}
                    alt={image.alt}
                    className="w-full h-full object-cover rounded-lg"
                  />
                </div>
              ))}
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
}

