import { Diamond,Star } from "lucide-react";
import { BookingCard } from "./SubBooking/bookingCard"
import { useState,useEffect } from "react";
import { useRef } from "react"

export default function BookingWidget() {

  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
        const handleScroll = () => {
          const scrollThreshold = 2000; 
          setIsVisible(window.scrollY < scrollThreshold);
        };
        window.addEventListener("scroll", handleScroll);
        return () => {
          window.removeEventListener("scroll", handleScroll);
        };
      }, []);

  return (
    <div className={` top-24 flex min-h-screen flex-col items-start  justify-start  ${isVisible ? "md:opacity-100" : "md:hidden md:pointer-events-none"}`} id="booking-section">
      <BookingCard />
      <RareFind/>
      <MobileBookingBar bookingComponentId="booking-section"/>
    </div>
  )
}

function RareFind() {
  return (
    <div className="flex items-center mt-3 md:w-[390px] gap-4 p-4 border rounded-lg shadow-sm bg-white">
      <Diamond className="text-pink-500 h-6 w-6" />
      <div>
        <h4 className="text-lg font-semibold">This is a rare find</h4>
        <p className="text-sm text-gray-600">
          Rob & Fang's place is usually fully booked.
        </p>
      </div>
    </div>
  );
}

interface MobileBookingBarProps {
  bookingComponentId: string;
}

function MobileBookingBar({ bookingComponentId }: MobileBookingBarProps) {
  const handleReserveClick = () => {
    const element = document.getElementById(bookingComponentId)
    if (element) {
      element.scrollIntoView({ behavior: "smooth" })
    }
  }

  return (
    <div className="fixed bottom-0 left-0 right-0 bg-white border-t p-4 md:hidden">
    <div className="flex items-center justify-between max-w-lg mx-auto">
      <div className="flex flex-col">
        <div className="flex items-baseline gap-1">
          <span className="text-l font-semibold">Add Dates For Prices</span>
          {/* <span className="text-sm text-gray-600">night</span> */}
        </div>
        <div className="flex items-center">
        <Star className="w-[20px] h-[20px] "/>
        <span className="text-sm text-gray-600">5.0</span>
        </div>
      </div>
      <button className="bg-[#E31C5F] hover:bg-[#D91959] text-white px-6 py-3 rounded-lg font-medium"
      onClick={handleReserveClick}
      >
        Check availability
      </button>
    </div>
  </div>
  )
}




//prev code
//hello there


// import React, { useState, useEffect } from "react";
// import { useNavigate } from "react-router-dom";
// import { Star, Diamond } from "lucide-react";
// import { Button } from "./ui/button";
// import { Card, CardContent, CardFooter, CardHeader } from "./ui/card";
// import { DateRangePicker } from "./DateRangePicker";
// import {
//   Select,
//   SelectContent,
//   SelectItem,
//   SelectTrigger,
//   SelectValue,
// } from "./ui/select";
// import { formatPrice } from "../lib/utils";
// import { addDays, differenceInDays } from "date-fns";
// export function BookingWidget() {
//   const navigate = useNavigate();
//   const [guests, setGuests] = useState("2");
//   const [dates, setDates] = useState({
//     from: new Date(),
//     to: addDays(new Date(), 3), // Default to 3 nights
//   });
//   const [isVisible, setIsVisible] = useState(true);
//   const originalPrice = 101;
//   const discountedPrice = 77; // Base price per night
//   const [total, setTotal] = useState({
//     nights: 3,
//     subtotal: 0,
//     isRefundable: true,
//   });
//   useEffect(() => {
//     if (dates.from && dates.to && guests) {
//       const nights = differenceInDays(dates.to, dates.from);
//       const guestCount = parseInt(guests, 10);
//       const perNightRate = discountedPrice + (guestCount - 1) * 10; // Extra charge per guest
//       const subtotal = perNightRate * nights;
//       setTotal((prevTotal) => ({
//         ...prevTotal,
//         nights,
//         subtotal,
//       }));
//     }
//   }, [dates, guests]);
//   useEffect(() => {
//     const handleScroll = () => {
//       const scrollThreshold = 2000; 
//       setIsVisible(window.scrollY < scrollThreshold);
//     };
//     window.addEventListener("scroll", handleScroll);
//     return () => {
//       window.removeEventListener("scroll", handleScroll);
//     };
//   }, []);
//   const handleRateChange = (isRefundable) => {
//     setTotal((prevTotal) => ({
//       ...prevTotal,
//       isRefundable,
//     }));
//   };
//   const handleReserve = () => {
//     navigate("/confirm-reservation", {
//       state: {
//         ...total,
//         dates,
//         guests,
//         originalPrice,
//         discountedPrice,
//       },
//     });
//     console.log({
//       ...total,
//       dates,
//       guests,
//       originalPrice,
//       discountedPrice,
//     });
//   };


// ${
//   isVisible ? "md:opacity-100" : "md:hidden md:pointer-events-none"
// }`

