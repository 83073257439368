import React from "react";

interface Payment {
  due: string;
  amount: string;
  isActive: boolean;
}

interface TimelineProps {
  payments: Payment[];
}

const Timeline: React.FC<TimelineProps> = ({ payments }) => {
  return (
    <div className="flex flex-col space-y-4 w-full">
      {payments.map((payment, index) => (
        <div key={index} className="flex flex-row items-center w-full">
          {/* Circle */}
          <div
            className={`w-4 h-4 rounded-full border-2 ${
              payment.isActive ? "border-black" : "border-gray-400"
            }`}
          ></div>

          {/* Vertical Line (not for last item) */}
          {index !== payments.length - 1 && (
            <div className="w-0.5 h-8 bg-gray-300 ml-2"></div>
          )}

          {/* Payment Details */}
          <div className="ml-4 flex justify-between w-full">
            <div
              className={`text-sm font-semibold ${
                payment.isActive ? "text-black" : "text-gray-500"
              }`}
            >
              {payment.due}
            </div>
            <div className="text-gray-600 text-sm">{payment.amount} lei</div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Timeline;
