"use client"

import { useState } from "react"
import { ChevronDown, ChevronUp, Minus, Plus } from 'lucide-react'
Button
import { Card } from "../ui/card"
import { Popover, PopoverContent, PopoverTrigger } from "../ui/popover"
import { Button } from "../ui/button"

export function GuestSelector({ value, onChange, maxGuests = 2 }) {
  const [isOpen, setIsOpen] = useState(false)

  const totalGuests = value.adults + value.children
  const isAtMaxGuests = totalGuests >= maxGuests

  const updateGuestCount = (type, increment) => {
    const newValue = { ...value }
    newValue[type] += increment

    if (type === 'adults' && newValue.adults < 1) {
      newValue.adults = 1
    } else if ((type === 'children' || type === 'infants') && newValue[type] < 0) {
      newValue[type] = 0
    }

    onChange(newValue)
  }

  return (
    <Popover open={isOpen} onOpenChange={setIsOpen}>
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          role="combobox"
          aria-expanded={isOpen}
          className="w-full justify-between"
        >
          {`${totalGuests} guest${totalGuests > 1 ? 's' : ''}${value.infants ? `, ${value.infants} infant${value.infants > 1 ? 's' : ''}` : ''}`}
          {isOpen ? <ChevronUp className="ml-2 h-4 w-4" /> : <ChevronDown className="ml-2 h-4 w-4" />}
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-80">
        <Card>
          <div className="space-y-4">
            
    {/* @ts-ignore */}
            <GuestTypeSelector
              label="Adults"
              subLabel="Age 13+"
              value={value.adults}
              onChange={(increment) => updateGuestCount('adults', increment)}
              min={1}
              max={maxGuests}
            />
            <GuestTypeSelector
              label="Children"
              subLabel="Ages 2–12"
              value={value.children}
              onChange={(increment) => updateGuestCount('children', increment)}
              max={maxGuests - value.adults}
              disabled={isAtMaxGuests}
            />
            {/* @ts-ignore */}
            <GuestTypeSelector
              label="Infants"
              subLabel="Under 2"
              value={value.infants}
              onChange={(increment) => updateGuestCount('infants', increment)}
              max={5}
            />
            <div className="text-sm text-gray-500">
              This place has a maximum of {maxGuests} guests, not including infants.
            </div>
          </div>
        </Card>
      </PopoverContent>
    </Popover>
  )
}

function GuestTypeSelector({ label, subLabel, value, onChange, min = 0, max, disabled }) {
  return (
    <div className="flex items-center justify-between">
      <div>
        <div className="font-medium">{label}</div>
        <div className="text-sm text-gray-500">{subLabel}</div>
      </div>
      <div className="flex items-center space-x-2">
        <Button
          variant="outline"
          size="icon"
          onClick={() => onChange(-1)}
          disabled={value <= min || disabled}
        >
          <Minus className="h-4 w-4" />
        </Button>
        <span className="w-8 text-center">{value}</span>
        <Button
          variant="outline"
          size="icon"
          onClick={() => onChange(1)}
          disabled={value >= max || disabled}
        >
          <Plus className="h-4 w-4" />
        </Button>
      </div>
    </div>
  )
}

