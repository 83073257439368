import React, { useState } from 'react';
import { Wifi, Tv, Car, Coffee, UtensilsCrossed, Wind, Dog, Fan, BedDouble,  Laptop } from 'lucide-react';
import { AmenitiesDialog } from "./AmenitiesDialog"
import { Button } from './ui/button';
import { Bed } from 'lucide-react'
import { Card, CardContent } from "./ui/card"
import { SelectSeparator } from './ui/select';
import { Dialog, DialogContent } from "./ui/dialog";


export function Amenities() {
  const [showAllIons, setShowAllIcons] = useState(false);

  const amenities = [
    { icon: Wifi, label: "Free WiFi" },
    { icon: Tv, label: "TV" },
    { icon: Car, label: "Free parking" },
    { icon: Coffee, label: "Coffee maker" },
    { icon: UtensilsCrossed, label: "Kitchen" },
    { icon: Wind, label: "Air conditioning" },
    { icon: Dog, label: "Pets allowed" },
    { icon: Laptop, label: "Dedicated workspace" },
    { icon: Fan, label: "Ceiling fan" },
    { icon: BedDouble, label: "King size bed" },
  ];

  return (
   <>

   <div className='mb-8'>
    <h2 className="text-xl font-semibold mb-3">Where you'll Sleep</h2>
    <BedroomCard/>
   </div>

   <SelectSeparator/>

    <div className="space-y-4">
      <h2 className="text-xl font-semibold mt-8">What this place offers</h2>
      <div className="grid grid-cols-2 gap-4 ">
        {amenities.map((amenity, i) => (
          <div key={i} className="flex items-center gap-2">
            <amenity.icon className="h-5 w-5 text-muted-foreground" />
            <span>{amenity.label}</span>
          </div>
        ))}
      </div>
      {/* For adding more icons */}

      <AmenitiesDialog/>
    </div>
    </>
  );
}

function BedroomCard() {
  return (
    <Card className="w-[150px]">
      <CardContent className="p-4 space-y-1">
        <Bed className="w-8 h-8 text-muted-foreground mb-2" />
        <h3 className="font-medium">Bedroom</h3>
        <p className="text-sm text-muted-foreground">1 double bed</p>
      </CardContent>
    </Card>
  )
}



