
import React, { useState } from 'react';
import { Star, StarIcon, Search, X, ChevronDown } from 'lucide-react';
import { Avatar, AvatarFallback, AvatarImage } from "./ui/avatar";
import { Button } from './ui/button';
import { Dialog, DialogContent } from "./ui/dialog";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "./ui/dropdown-menu"
import { Input } from "./ui/input";

export function Reviews() {
  const [showAllReviews, setShowAllReviews] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');

  const reviews = [
    {
      author: "Nilesh",
      date: "3 days ago",
      rating: 5,
      content: "One of the best villa that we have stayed in goa,excellent caretaker, very clean and spacious room, most cafe are a walk away",
      avatar: "https://media.licdn.com/dms/image/D5603AQFfgVOzSHBDAw/profile-displayphoto-shrink_200_200/0/1713388726768?e=2147483647&v=beta&t=b991v82l2cR7JhAQB28UCflgtfPDGxGCXmDJaF3GMqU",
      yearsOnPlatform: "9 years on Airbnb"
    },
    {
      author: "Robin",
      date: "6 days ago",
      rating: 5,
      content: "Amazing stay, very friendly host and what a spot !! We loved the villa, and the location. Would love to come again for sure !",
      avatar: "https://media.licdn.com/dms/image/D5603AQFfgVOzSHBDAw/profile-displayphoto-shrink_200_200/0/1713388726768?e=2147483647&v=beta&t=b991v82l2cR7JhAQB28UCflgtfPDGxGCXmDJaF3GMqU",
      yearsOnPlatform: "11 years on Airbnb"
    },
    {
      author: "Robin",
      date: "6 days ago",
      rating: 5,
      content: "Amazing stay, very friendly host and what a spot !! We loved the villa, and the location. Would love to come again for sure !",
      avatar: "https://media.licdn.com/dms/image/D5603AQFfgVOzSHBDAw/profile-displayphoto-shrink_200_200/0/1713388726768?e=2147483647&v=beta&t=b991v82l2cR7JhAQB28UCflgtfPDGxGCXmDJaF3GMqU",
      yearsOnPlatform: "11 years on Airbnb"
    },
    {
      author: "Robin",
      date: "6 days ago",
      rating: 5,
      content: "Amazing stay, very friendly host and what a spot !! We loved the villa, and the location. Would love to come again for sure !",
      avatar: "https://media.licdn.com/dms/image/D5603AQFfgVOzSHBDAw/profile-displayphoto-shrink_200_200/0/1713388726768?e=2147483647&v=beta&t=b991v82l2cR7JhAQB28UCflgtfPDGxGCXmDJaF3GMqU",
      yearsOnPlatform: "11 years on Airbnb"
    },
    {
      author: "Robin",
      date: "6 days ago",
      rating: 5,
      content: "Amazing stay, very friendly host and what a spot !! We loved the villa, and the location. Would love to come again for sure !",
      avatar: "https://media.licdn.com/dms/image/D5603AQFfgVOzSHBDAw/profile-displayphoto-shrink_200_200/0/1713388726768?e=2147483647&v=beta&t=b991v82l2cR7JhAQB28UCflgtfPDGxGCXmDJaF3GMqU",
      yearsOnPlatform: "11 years on Airbnb"
    },
    {
      author: "Robin",
      date: "6 days ago",
      rating: 5,
      content: "Amazing stay, very friendly host and what a spot !! We loved the villa, and the location. Would love to come again for sure !",
      avatar: "https://media.licdn.com/dms/image/D5603AQFfgVOzSHBDAw/profile-displayphoto-shrink_200_200/0/1713388726768?e=2147483647&v=beta&t=b991v82l2cR7JhAQB28UCflgtfPDGxGCXmDJaF3GMqU",
      yearsOnPlatform: "11 years on Airbnb"
    },
    {
      author: "Robin",
      date: "6 days ago",
      rating: 5,
      content: "Amazing stay, very friendly host and what a spot !! We loved the villa, and the location. Would love to come again for sure !",
      avatar: "https://media.licdn.com/dms/image/D5603AQFfgVOzSHBDAw/profile-displayphoto-shrink_200_200/0/1713388726768?e=2147483647&v=beta&t=b991v82l2cR7JhAQB28UCflgtfPDGxGCXmDJaF3GMqU",
      yearsOnPlatform: "11 years on Airbnb"
    },
    {
      author: "Robin",
      date: "6 days ago",
      rating: 5,
      content: "Amazing stay, very friendly host and what a spot !! We loved the villa, and the location. Would love to come again for sure !",
      avatar: "https://media.licdn.com/dms/image/D5603AQFfgVOzSHBDAw/profile-displayphoto-shrink_200_200/0/1713388726768?e=2147483647&v=beta&t=b991v82l2cR7JhAQB28UCflgtfPDGxGCXmDJaF3GMqU",
      yearsOnPlatform: "11 years on Airbnb"
    },
    // ... other reviews remain the same
  ];

  const categories = [
    {
      label: "Overall rating",
      ratings: [
        { score: 5, percentage: 95 },
        { score: 4, percentage: 4 },
        { score: 3, percentage: 1 },
        { score: 2, percentage: 0 },
        { score: 1, percentage: 0 },
      ],
    },
    { label: "Cleanliness", value: "5.0", icon: "🧹" },
    { label: "Accuracy", value: "5.0", icon: "✓" },
    { label: "Check-in", value: "5.0", icon: "🔑" },
    { label: "Communication", value: "5.0", icon: "💬" },
    { label: "Location", value: "4.9", icon: "📍" },
    { label: "Value", value: "4.8", icon: "💰" },
  ];

  return (
    <div className='md:w-[1200px]'>
      <RatingComponent />
      <div className="space-y-6">
        <div className="md:grid md:grid-cols-2 md:gap-6">
          {reviews.slice(0, 6).map((review, i) => (
            <ReviewCard key={i} review={review} />
          ))}
        </div>
        <Button onClick={() => setShowAllReviews(true)} variant='outline'>
          Show all Reviews
        </Button>

        <Dialog open={showAllReviews} onOpenChange={setShowAllReviews}>
          <DialogContent className="max-w-5xl w-full h-[90vh] p-4 sm:p-6 overflow-scroll flex flex-col">
  <div className='flex flex-col md:flex-row'>
            <div className='md:w-[40%]'>
            
            



            <div className="max-w-5xl mx-auto px-4 sm:px-6 py-6 sm:py-8">
      <div className="text-center mb-6 sm:mb-8">
        <div className="justify-center w-full mb-4 flex">
          <div className='flex w-full justify-center items-center'>
            <img src="../../icons/LeafIcon1.ico" alt="" className='w-[80px] h-[80px] sm:w-[115px] sm:h-[115px]' />
            <span className="text-4xl sm:text-5xl md:text-[64px] leading-none font-semibold text-gray-900">4.97</span>
            <img src="../../icons/LeafIcon2.ico" className='w-[70px] h-[70px] sm:w-[103px] sm:h-[103px] mb-2' alt="" />
          </div>
        </div>
        <h2 className="text-lg sm:text-xl md:text-2xl font-medium text-gray-800 mb-2">Guest favorite</h2>
        <p className="text-xs sm:text-sm md:text-base text-gray-500 max-w-md mx-auto">
          One of the most loved homes on Airbnb based on ratings, reviews, and reliability
        </p>
      </div>

      <div className="grid grid-cols-2   gap-4 sm:gap-6">
        <div className="col-span-2 mb-6 sm:mb-0">
          <h3 className="text-xs sm:text-sm font-medium text-gray-700 mb-2 sm:mb-3">{categories[0].label}</h3>
          <div className="space-y-1 sm:space-y-2">
            {categories[0].ratings.map((rating) => (
              <div key={rating.score} className="flex items-center gap-1 sm:gap-2">
                <span className="text-xs text-gray-600 w-3">{rating.score}</span>
                <div className="flex-1 h-1 bg-gray-100 rounded-full">
                  <div
                    className="h-full bg-gray-700 rounded-full"
                    style={{ width: `${rating.percentage}%` }}
                  />
                </div>
                <span className="text-xs text-gray-600 w-8">{rating.percentage}%</span>
              </div>
            ))}
          </div>
        </div>

        {categories.slice(1).map((category) => (
          <div key={category.label} className="col-span-1">
            <div className="flex flex-col items-center">
              <span className="text-lg sm:text-xl mb-1 sm:mb-2">{category.icon}</span>
              <span className="text-xs sm:text-sm text-gray-500 mb-1">{category.label}</span>
              <span className="text-xs sm:text-sm md:text-base font-medium">{category.value}</span>
            </div>
          </div>
        ))}
      </div>


    </div>





            </div>
     <div className='md:w-[60%]'>
            <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-4 sm:mb-6 gap-4">
              <div className="flex flex-col sm:flex-row items-start sm:items-center gap-4">
                <h2 className="text-xl sm:text-2xl font-semibold">38 reviews</h2>
                <div className="relative w-full sm:w-auto">
                  <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 h-4 w-4 text-gray-500" />
                  <Input
                    className="pl-10 w-full sm:w-[300px]"
                    placeholder="Search reviews"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                  />
                </div>
              </div>
              <div className="flex items-center gap-4">
                <DropdownMenu>
                  <DropdownMenuTrigger asChild>
                    <Button variant="outline" className="flex items-center gap-2">
                      Most recent
                      <ChevronDown className="h-4 w-4" />
                    </Button>
                  </DropdownMenuTrigger>
                  <DropdownMenuContent>
                    <DropdownMenuItem>Most recent</DropdownMenuItem>
                    <DropdownMenuItem>Highest rated</DropdownMenuItem>
                    <DropdownMenuItem>Lowest rated</DropdownMenuItem>
                  </DropdownMenuContent>
                </DropdownMenu>
                
              </div>
            </div>

            <div className="grid grid-cols-1 gap-4 sm:gap-6 overflow-y-auto flex-grow">
              {reviews.map((review, i) => (
                <ReviewCard key={i} review={review} />
              ))}
            </div>
      </div>
</div>
          </DialogContent>
        </Dialog>
      </div>
    </div>
  );
}

function ReviewCard({ review }) {
  return (
    <div className="space-y-4 p-3 sm:p-4 border rounded-md">
      <div className="flex items-start sm:items-center gap-3 sm:gap-4">
        <Avatar>
          <AvatarImage src={review.avatar} alt={review.author} />
          <AvatarFallback>{review.author[0]}</AvatarFallback>
        </Avatar>
        <div>
          <div className="font-semibold">{review.author}</div>
          <div className="text-xs sm:text-sm text-muted-foreground flex flex-col sm:flex-row sm:gap-2">
            <span>{review.yearsOnPlatform}</span>
            <span>{review.date}</span>
          </div>
          <div className="flex mt-1 sm:mt-2">
            {Array.from({ length: review.rating }).map((_, i) => (
              <Star key={i} className="w-3 h-3 sm:w-[15px] sm:h-[15px] fill-current" />
            ))}
          </div>
        </div>
      </div>
      <p className="text-sm sm:text-base text-muted-foreground">{review.content}</p>
    </div>
  );
}

function RatingComponent() {
  const categories = [
    {
      label: "Overall rating",
      ratings: [
        { score: 5, percentage: 95 },
        { score: 4, percentage: 4 },
        { score: 3, percentage: 1 },
        { score: 2, percentage: 0 },
        { score: 1, percentage: 0 },
      ],
    },
    { label: "Cleanliness", value: "5.0", icon: "🧹" },
    { label: "Accuracy", value: "5.0", icon: "✓" },
    { label: "Check-in", value: "5.0", icon: "🔑" },
    { label: "Communication", value: "5.0", icon: "💬" },
    { label: "Location", value: "4.9", icon: "📍" },
    { label: "Value", value: "4.8", icon: "💰" },
  ];

  return (
    <div className="max-w-5xl mx-auto px-4 sm:px-6 py-6 sm:py-8">
      <div className="text-center mb-6 sm:mb-8">
        <div className="justify-center w-full mb-4 flex">
          <div className='flex w-full justify-center items-center'>
            <img src="../../icons/LeafIcon1.ico" alt="" className='w-[80px] h-[80px] sm:w-[115px] sm:h-[115px]' />
            <span className="text-4xl sm:text-5xl md:text-[64px] leading-none font-semibold text-gray-900">4.97</span>
            <img src="../../icons/LeafIcon2.ico" className='w-[70px] h-[70px] sm:w-[103px] sm:h-[103px] mb-2' alt="" />
          </div>
        </div>
        <h2 className="text-lg sm:text-xl md:text-2xl font-medium text-gray-800 mb-2">Guest favorite</h2>
        <p className="text-xs sm:text-sm md:text-base text-gray-500 max-w-md mx-auto">
          One of the most loved homes on Airbnb based on ratings, reviews, and reliability
        </p>
      </div>

      <div className="sm:grid grid-cols-2 hidden sm:grid-cols-4 md:grid-cols-8 gap-4 sm:gap-6">
        <div className="col-span-2 mb-6 sm:mb-0">
          <h3 className="text-xs sm:text-sm font-medium text-gray-700 mb-2 sm:mb-3">{categories[0].label}</h3>
          <div className="space-y-1 sm:space-y-2">
            {categories[0].ratings.map((rating) => (
              <div key={rating.score} className="flex items-center gap-1 sm:gap-2">
                <span className="text-xs text-gray-600 w-3">{rating.score}</span>
                <div className="flex-1 h-1 bg-gray-100 rounded-full">
                  <div
                    className="h-full bg-gray-700 rounded-full"
                    style={{ width: `${rating.percentage}%` }}
                  />
                </div>
                <span className="text-xs text-gray-600 w-8">{rating.percentage}%</span>
              </div>
            ))}
          </div>
        </div>

        {categories.slice(1).map((category) => (
          <div key={category.label} className="col-span-1">
            <div className="flex flex-col items-center">
              <span className="text-lg sm:text-xl mb-1 sm:mb-2">{category.icon}</span>
              <span className="text-xs sm:text-sm text-gray-500 mb-1">{category.label}</span>
              <span className="text-xs sm:text-sm md:text-base font-medium">{category.value}</span>
            </div>
          </div>
        ))}
      </div>


    </div>
  );
}

