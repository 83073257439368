import { ChevronLeft } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { PropertyPreview } from "../components/PropertyPreview";
import { ReservationForm } from "../components/ReservationForm";
import { Button } from "../components/ui/button";
import { useRecoilValue } from "recoil";

export default function ConfirmReservationPage() {
  const navigate = useNavigate();
  // const total = useRecoilValue(totalAmount)

  return (
    <div className="min-h-screen bg-white py-8">
      <div className="mx-auto max-w-7xl px-4 mt-20 sm:px-6 lg:px-8">
        <div className="flex mb-6 items-center ">
          <Button
            variant="ghost"
            className="text-lg p-0 mr-2"
            onClick={() => navigate("/")}
          >
            <ChevronLeft className="mr-2 h-5 w-5" />
          </Button>
          <h3 className="text-3xl">Confirm reservation</h3>
        </div>

        <div className="grid grid-cols-1 gap-8 lg:grid-cols-2">
          <div>
            <ReservationForm
              // onSubmit={handleSubmit}
              //  isSubmitting={isSubmitting}
              //@ts-ignore
              dates={45}
              guests={45}
            />
            <div className="divider my-10"></div>
            {/* <PolicySection /> */}
          </div>
          <div className="space-y-6">
            <PropertyPreview
            />
          </div>
        </div>
      </div>
    </div>
  );
}
