"use client"

import { RadioGroup, RadioGroupItem } from "../ui/radio-group"
import { Label } from "../ui/label"
import { useRecoilState } from "recoil";
import { totalAmount, nonRefund } from "../../recoil/atom";

export function RatesSelector({ onSelect, selectedRate }) {
  const [amount, setAmount] = useRecoilState(totalAmount);
  const [isNonRefundable, setIsNonRefundable] = useRecoilState(nonRefund);
  console.log(amount)

  return (
    <div className="space-y-4">
      <h3 className="font-semibold text-base">RATES</h3>
      <RadioGroup 
        defaultValue="non-refundable" 
        value={selectedRate}
        onValueChange={(value) => {
          onSelect(value);
          setIsNonRefundable(value === "non-refundable");
          if (value === "non-refundable") {
            setAmount(prevAmount => prevAmount - 25);
          } else {
            setAmount(prevAmount => prevAmount + 25);
          }
        }}
        className="space-y-2"
      >
        <div className="flex items-center space-x-2 border rounded-lg p-4">
          <RadioGroupItem value="non-refundable" id="non-refundable" 
          
          />
          
          <Label 
            htmlFor="non-refundable" 
            className="flex-1 cursor-pointer"
          >
            <div>Non-refundable · € {amount} total</div>
          </Label>
        </div>
        <div className="flex items-center space-x-2 border rounded-lg p-4">
          <RadioGroupItem value="refundable" id="refundable" />
          <Label 
            htmlFor="refundable" 
            className="flex-1 cursor-pointer"
          >
            <div>Refundable · €{amount + 25} total</div>
            <div className="text-sm text-muted-foreground">
              Free cancellation for 48 hours. Cancel before Jan 1 for a partial refund.
            </div>
          </Label>
        </div>
      </RadioGroup>
    </div>
  )
}





