import { Star } from "lucide-react";
import { FaTags } from "react-icons/fa";
import { formatPrice } from "../lib/utils";
import Timeline from "./ui/Timeline";
import { Badge } from "./ui/badge";
import { Card, CardContent } from "./ui/card";
import { useRecoilValue } from "recoil";
import { discountedPrice,   totalAmount } from "../recoil/atom";

export function PropertyPreview() {
  const total = useRecoilValue(totalAmount)
  const monthly = total / 12
  const discount = useRecoilValue(discountedPrice)

  const payments = [
    { due: "Due today", amount: total, isActive: true },
    { due: "Due Feb 22, 2025", amount: (total-monthly), isActive: false },
  ];

  return (
    <Card>
      <CardContent className="p-4">
        <div className="flex gap-4">
          <div className="relative h-24 w-24 flex-shrink-0 overflow-hidden rounded-lg">
            <img
              src="/room2.png"
              alt="AmstNest"
              className="h-full w-full object-cover"
            />
          </div>
          <div className="flex-1">
            <h3 className="font-semibold">
              AmstNest - an urban retreat for two
            </h3>
            <p className="text-sm text-muted-foreground">Room in townhouse</p>
            <div className="mt-1 flex items-center gap-1">
              <Star className="h-4 w-4" />
              <span className="text-sm font-medium">4.90</span>
              <span className="text-sm text-muted-foreground">
                (558 reviews)
              </span>
              <Badge variant="secondary" className="ml-2">
                Superhost
              </Badge>
            </div>
          </div>
        </div>

        <div className="divider my-5"></div>

        <div>
          <div className="mb-5">
            <h3 className="text-lg font-semibold">Monthly payments</h3>
            <p className="text-sm">
              Average: {formatPrice((total/12))} before taxes and fees
            </p>
          </div>

          <Timeline 
          //@ts-ignore
          payments={payments} />

          <button
            className="text-sm text-primary underline w-full text-left mt-5 mb-9"
            onClick={() => {
              /* Show details modal */
            }}
          >
            Show details
          </button>

          <div className="rounded-lg bg-[#F5FFF9] p-4 flex items-center justify-between">
            <div className="w-[80%]">
              <div className="flex items-start gap-2">
                <Badge variant="outline" className="bg-[#E3F5EB] border-0">
                  You'll save {formatPrice( discount)}
                </Badge>
              </div>
              <p className="mt-2 text-sm text-muted-foreground">
                Savings include a weekly discount and a lower Airbnb service fee
                for staying longer.
              </p>
            </div>
            <div className="flex justify-end">
              <FaTags size={40} className="w-full text-green-400" />
            </div>
          </div>
        </div>
      </CardContent>
    </Card>
  );
}
