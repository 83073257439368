"use client"

import * as React from "react"
import { ChevronLeft, ChevronRight, X } from 'lucide-react'
import { Button } from "../ui/button"
import { Popover, PopoverContent, PopoverTrigger } from "../ui/popover"
import { cn } from "../../lib/utils"


const WEEKDAYS = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"]
const MONTHS = [
  "January", "February", "March", "April", "May", "June",
  "July", "August", "September", "October", "November", "December"
]

export function DatePicker({ startDate, endDate, onDateChange, open, onOpenChange }) {
  const [currentMonth, setCurrentMonth] = React.useState(new Date().getMonth())
  const [currentYear, setCurrentYear] = React.useState(new Date().getFullYear())

  const goToPreviousMonth = () => {
    if (currentMonth === 0) {
      setCurrentMonth(11)
      setCurrentYear(currentYear - 1)
    } else {
      setCurrentMonth(currentMonth - 1)
    }
  }

  const goToNextMonth = () => {
    if (currentMonth === 11) {
      setCurrentMonth(0)
      setCurrentYear(currentYear + 1)
    } else {
      setCurrentMonth(currentMonth + 1)
    }
  }

  const handleDateSelect = (date) => {
    if (!startDate || (startDate && endDate)) {
      onDateChange({ startDate: date, endDate: null })
    } else {
      if (date < startDate) {
        onDateChange({ startDate: date, endDate: null })
      } else {
        onDateChange({ startDate, endDate: date })
      }
    }
  }

  const clearDates = () => {
    onDateChange({ startDate: null, endDate: null })
  }

  const handleClose = () => {
    onOpenChange(false)
  }

  const isDateInRange = (date) => {
    if (!startDate || !endDate) return false
    return date > startDate && date < endDate
  }

  const isStartDate = (date) => {
    return startDate && date.toDateString() === startDate.toDateString()
  }

  const isEndDate = (date) => {
    return endDate && date.toDateString() === endDate.toDateString()
  }

  const getNightCount = () => {
    if (!startDate || !endDate) return 0
    const diffTime = Math.abs(endDate - startDate)
    return Math.ceil(diffTime / (1000 * 60 * 60 * 24))
  }

  const renderMonth = (monthOffset = 0) => {
    let month = currentMonth + monthOffset
    let year = currentYear
    if (month > 11) {
      month = 0
      year += 1
    }

    const daysInMonth = new Date(year, month + 1, 0).getDate()
    const firstDayOfMonth = new Date(year, month, 1).getDay()
    const days = []

    // Empty cells for days before the first day of the month
    for (let i = 0; i < firstDayOfMonth; i++) {
      days.push(<div key={`empty-${i}`} className="h-10" />)
    }

    // Days of the month
    for (let day = 1; day <= daysInMonth; day++) {
      const date = new Date(year, month, day)
      const isSelected = isDateInRange(date)
      const isStart = isStartDate(date)
      const isEnd = isEndDate(date)
      const isPast = date < new Date(new Date().setHours(0, 0, 0, 0))

      days.push(
        <Button
          key={day}
          variant="ghost"
          className={cn(
            "h-10 w-10 p-0 font-normal",
            isSelected && "bg-accent hover:bg-accent",
            (isStart || isEnd) && "bg-primary text-primary-foreground hover:bg-primary hover:text-primary-foreground rounded-full",
            isPast && "text-muted-foreground hover:bg-transparent cursor-not-allowed"
          )}
          disabled={isPast}
          onClick={() => handleDateSelect(date)}
        >
          {day}
        </Button>
      )
    }

    return (
      <div className="space-y-4">
        <div className="text-base font-semibold">{MONTHS[month]} {year}</div>
        <div className="grid grid-cols-7 gap-1 text-sm">
          {WEEKDAYS.map(day => (
            <div key={day} className="h-10 font-medium text-center py-2">
              {day}
            </div>
          ))}
          {days}
        </div>
      </div>
    )
  }

  return (
    <Popover open={open} onOpenChange={onOpenChange}>
      <PopoverTrigger asChild>
        <div className="grid grid-cols-2 gap-2 border rounded-lg p-2 ">
          <div className="space-y-1">
            <div className="text-xs font-semibold">CHECK-IN</div>
            <div className="flex items-center justify-between">
              <span className="text-sm">
                {startDate ? startDate.toLocaleDateString() : "Add date"}
              </span>
              {startDate && (
                <Button
                  variant="ghost"
                  size="sm"
                  className="h-4 w-4 p-0"
                  onClick={(e) => {
                    e.stopPropagation()
                    onDateChange({ startDate: null, endDate })
                  }}
                >
                  <X className="h-3 w-3" />
                </Button>
              )}
            </div>
          </div>
          <div className="space-y-1">
            <div className="text-xs font-semibold">CHECKOUT</div>
            <div className="flex items-center justify-between">
              <span className="text-sm">
                {endDate ? endDate.toLocaleDateString() : "Add date"}
              </span>
              {endDate && (
                <Button
                  variant="ghost"
                  size="sm"
                  className="h-4 w-4 p-0"
                  onClick={(e) => {
                    e.stopPropagation()
                    onDateChange({ startDate, endDate: null })
                  }}
                >
                  <X className="h-3 w-3" />
                </Button>
              )}
            </div>
          </div>
        </div>
      </PopoverTrigger>
      <PopoverContent className="md:w-auto w-[350px] p-0 overflow-scroll h-[350px]" align="start">
        <div className="p-4 space-y-4 ">
          {/* Header */}
          {startDate && endDate && (
            <div>
              <div className="text-xl font-semibold">
                {getNightCount()} nights
              </div>
              <div className="text-muted-foreground">
                {startDate.getDate()} {MONTHS[startDate.getMonth()]} {startDate.getFullYear()} - {" "}
                {endDate.getDate()} {MONTHS[endDate.getMonth()]} {endDate.getFullYear()}
              </div>
            </div>
          )}

          {/* Calendar */}
          <div className="relative">
            <Button
              variant="ghost"
              size="icon"
              className="absolute left-0 top-1/2 -translate-y-1/2"
              onClick={goToPreviousMonth}
            >
              <ChevronLeft className="h-4 w-4" />
            </Button>
            <div className="grid md:grid-cols-2 gap-8 px-10">
              {renderMonth(0)}
              {renderMonth(1)}
            </div>
            <Button
              variant="ghost"
              size="icon"
              className="absolute right-0 top-1/2 -translate-y-1/2"
              onClick={goToNextMonth}
            >
              <ChevronRight className="h-4 w-4" />
            </Button>
          </div>

          {/* Footer */}
          <div className="flex items-center justify-between pt-4 border-t">
            <Button
              variant="ghost"
              className="underline"
              onClick={clearDates}
            >
              Clear dates
            </Button>
            <Button
              className="bg-black text-white hover:bg-black/90"
              onClick={handleClose}
            >
              Close
            </Button>
          </div>
        </div>
      </PopoverContent>
    </Popover>
  )
}

